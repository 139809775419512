import * as React from 'react';
import {
  reportDispatch, reportPlotDispatch, reportPlotKeysDispatch, reportPlotOptionsDispatch,
} from '../report-utils';

function ReportPlotInitialLoader({ plot }) {
  const updateReportPlot = reportPlotDispatch(plot.key);

  React.useEffect(() => {
    updateReportPlot(plot);
  });

  return null;
}

export function ReportInitialLoader({ report, plotOptions }) {
  const updateReport = reportDispatch();
  const updatePlotOptions = reportPlotOptionsDispatch();
  const updatePlotKeys = reportPlotKeysDispatch();

  React.useEffect(() => {
    updateReport(report);
    updatePlotOptions(plotOptions);
    updatePlotKeys(report.plots.map((plot) => plot.key));
  });

  return report.plots.map((plot) => (
    <ReportPlotInitialLoader key={plot.key} plot={plot} />
  ));
}
