import * as React from 'react';
import { Col, Modal } from 'react-bootstrap';
import { GlobalWrapper } from '../GlobalWrapper.jsx';
import { Dashbox, DashboxBody, DashboxTitle } from '../../duxfront/duxdash/components/Dashbox.jsx';
import { FileInput, Form, Input } from '../../duxfront/duxstrap/components/Form.jsx';
import { BootBox } from '../../duxfront/duxstrap/vendor/bootbox';
import { Button } from '../../duxfront/duxstrap/components/Button.jsx';
import { Icon } from '../../duxfront/duxstrap/components/Icon.jsx';
import { Spacer } from '../../duxfront/duxstrap/components/Spacer.jsx';
import { Translator } from '../../global/translator';
import useMaps from '../../hooks/use-maps';
import { convertKmlToGeoJson } from '../../lib/map';

export function Maps(props) {
  const translator = new Translator();
  const {
    maps,
    selectedMap,
    modal,
    canEdit,
    closeModal,
    showModal,
    createOrUpdate,
    destroy,
  } = useMaps();

  return (
    <GlobalWrapper {...props}>
      <Dashbox className="h-100">
        <DashboxTitle title={translator.get('general.maps')}>
          { canEdit && (
          <Button rounded icon size="sm" variant="outline-primary" onClick={() => showModal(null)}>
            <Icon name="plus" offset={1} />
          </Button>
          )}
        </DashboxTitle>
        <DashboxBody table style={{ maxHeight: '300px' }}>
          { maps && maps.length > 0 && maps.map((map) => (
            <tr key={map.id} className="cursor-pointer" onClick={() => showModal(map)}>
              <td className="px-3">
                <div className="font-weight-accent">{map.title}</div>
              </td>
            </tr>
          ))}

          { maps.length === 0 && (
          <tr>
            <td className="px-3">
              <p>{translator.get('messages.no-results-found')}</p>
            </td>
          </tr>
          )}
        </DashboxBody>
      </Dashbox>
      <MapModal
        show={modal}
        map={selectedMap}
        canEdit={canEdit}
        onClose={closeModal}
        createOrUpdate={createOrUpdate}
        destroy={destroy}
      />
    </GlobalWrapper>
  );
}

function MapModal({
  map,
  show,
  onClose,
  canEdit,
  createOrUpdate,
  destroy,
}) {
  const self = React.createRef();
  const translator = new Translator();
  const [title, setTitle] = React.useState('');
  const [content, setContent] = React.useState('');

  React.useEffect(() => {
    setTitle(map?.title ?? '');
    setContent(map?.content ?? '');
  }, [map]);

  const handleKmlFile = React.useCallback((files) => {
    // eslint-disable-next-line no-undef
    const fileReader = new FileReader();
    fileReader.readAsText(files[0], 'UTF-8');
    fileReader.onload = (e) => {
      setContent(convertKmlToGeoJson(e.target.result));
    };
  });

  const submitMap = React.useCallback((e) => {
    e.preventDefault();

    if (!content) {
      BootBox.alert(translator.get('messages.kml-required'));
      return;
    }

    createOrUpdate(title, content);
  });

  return (
    <Modal centered show={show} onHide={onClose}>
      <Modal.Header closeButton onHide={onClose}>
        <Modal.Title>
          {map ? translator.get('general.update') : translator.get('general.add')}
          { ` ${translator.get('general.map')}` }
        </Modal.Title>
      </Modal.Header>
      <Modal.Body ref={self} className="pt-2 px-3">
        <Form onSubmit={submitMap} validate withBorders>
          <Col sm={12}>
            <Input
              id="title"
              label={translator.get('general.title')}
              defaultValue={map?.title}
              readOnly={!canEdit}
              onChange={(e) => setTitle(e.target.value)}
              validation="required"
            />
          </Col>
          <Col sm={12}>
            <FileInput
              id="content"
              label="KML"
              onSelection={handleKmlFile}
              readOnly={!canEdit}
              accept=".kml"
            />
          </Col>
          { canEdit && (
            <>
              <Spacer block />
              <Col sm={6}>
                {
                  map
                    ? (
                      <Button size="sm" variant="danger" onClick={() => destroy()} withIcon block>
                        <Icon name="trash" />
                        { translator.get('general.delete').toUpperCase() }
                      </Button>
                    )
                    : (
                      <Button size="sm" variant="gray-200" onClick={onClose} withIcon block>
                        <Icon name="times" />
                        { translator.get('general.cancel').toUpperCase() }
                      </Button>
                    )
                }
              </Col>
              <Col sm={6}>
                <Button size="sm" variant="primary" type="submit" withIcon block>
                  <Icon name="check" />
                  { translator.get('general.save').toUpperCase() }
                </Button>
              </Col>
            </>
          )}
        </Form>
      </Modal.Body>
    </Modal>
  );
}
