import * as React from 'react';
import { Provider } from 'react-redux';
import { globalObject } from '../global/global_object';
import { globalStore } from '../duxfront/plugins/dux-redux';

export function GlobalWrapper(props) {
  const {
    duxfront,
    translations,
    children,
    user = null,
    project = null,
    permissions = null,
    authenticityToken = null,
  } = props;

  globalObject.duxfront = duxfront;
  globalObject.translations = translations;
  globalObject.user = user;
  globalObject.project = project;
  globalObject.permissions = permissions;
  globalObject.authenticityToken = authenticityToken;

  return (
    <Provider store={globalStore()}>
      { children }
    </Provider>
  );
}
