import { Col } from 'react-bootstrap';
import * as React from 'react';
import { Input, Select } from '../../../duxfront/duxstrap/components/Form.jsx';
import { Button } from '../../../duxfront/duxstrap/components/Button.jsx';
import { Icon } from '../../../duxfront/duxstrap/components/Icon.jsx';
import { Spacer } from '../../../duxfront/duxstrap/components/Spacer.jsx';
import {
  parseForm, patchJSON, removeByKey, updateByKeyValue,
} from '../../../duxfront/plugins/dux-utils';
import {
  loadSources,
  reportErrorDispatch,
  reportPlotDispatch,
  reportPlotOptionsSelector,
  reportPlotSelector,
  reportSelector,
} from '../report-utils';
import { Translator } from '../../../global/translator';
import { ReportPlotFormWrapper } from './ReportPlotFormWrapper.jsx';

function ReportDataVariableForm({
  parentFormId, sources, variables, setVariables, selectedVariable, setSelectedVariable,
}) {
  const translator = new Translator();
  const formId = `${parentFormId}_variable`;

  const addVariable = React.useCallback((e) => {
    e.preventDefault();

    const formData = parseForm(e.target);
    const newVariable = {
      label: formData[formId].label,
      column: formData[formId].metric,
      stat: formData[formId].stat,
    };

    setVariables(updateByKeyValue(variables, newVariable, selectedVariable.label, 'label'));
    setSelectedVariable(null);
  });

  const removeVariable = React.useCallback(() => {
    setVariables(removeByKey(variables, selectedVariable, 'label'));
    setSelectedVariable(null);
  });

  return (
    <ReportPlotFormWrapper formId={formId} onSave={addVariable} onRemove={removeVariable}>
      <Col xs={6}>
        <Input
          id="label"
          formId={formId}
          label={translator.get('general.variable-name')}
          defaultValue={selectedVariable.label}
          placeholder="(x * 1.0) + 0.0"
        />
      </Col>
      <ReportDataSourceSelection
        formId={formId}
        sources={sources}
        metricCode={selectedVariable.column}
        stat={selectedVariable.stat || 'mean'}
      />
    </ReportPlotFormWrapper>
  );
}

function ReportDataVariableList({
  sources, variables, setSelectedVariable,
}) {
  const translator = new Translator();

  const variableWithDetails = sources && variables.map((variable) => {
    let sourceName = 'none';
    let metricName = 'none';

    sources.forEach((source) => {
      source.metrics.forEach((metric) => {
        if (metric.value === variable.column) {
          sourceName = source.name;
          metricName = metric.name;
        }
      });
    });

    return {
      ...variable,
      column: variable.column || 'none',
      sourceName,
      metricName,
    };
  });

  const addVariable = React.useCallback(() => {
    setSelectedVariable({
      label: 'newVar',
      column: 'none',
      stat: 'none',
    });
  });

  return variableWithDetails && (
    <>
      <Col sm={12} className="mt-2 fs-12 text-heading text-uppercase text-gray-600">
        {`${translator.get('general.variables')}:`}
      </Col>
      {
        variableWithDetails.map((variable) => (
          <Col sm={6} key={variable.label} onClick={() => setSelectedVariable(variable)}>
            <div className="cursor-pointer border rounded p-2">
              <div className="fs-14 font-weight-bold">{variable.label}</div>
              <div className="fs-12 text-gray-500">{variable.sourceName}</div>
              <div className="fs-12 text-gray-500">{variable.metricName}</div>
              <div className="fs-12 text-gray-500">{variable.stat}</div>
            </div>
          </Col>
        ))
      }
      <Col className="d-flex align-items-center justify-content-center">
        <Button size="xs" variant="outline-primary" onClick={addVariable} withIcon className="text-uppercase pr-5 pl-6">
          <Icon name="plus" offset={2} />
          {`${translator.get('general.add')} ${translator.get('general.variable')}`}
        </Button>
      </Col>
    </>
  );
}

function ReportDataSourceSelection({
  formId, sources, metricCode, stat,
}) {
  const translator = new Translator();
  const plotOptions = reportPlotOptionsSelector();
  const [selectedMetric, setSelectedMetric] = React.useState(null);
  const [selectedSource, setSelectedSource] = React.useState(null);

  const loadSelectedSource = React.useCallback(() => {
    let sourceToSelect = sources.find(
      (source) => !!source.metrics.find((metric) => metric.value === metricCode),
    );

    if (!sourceToSelect) {
      sourceToSelect = sources.find((source) => source.metrics.length > 0);
    }

    setSelectedMetric(metricCode || 'none');
    setSelectedSource(sourceToSelect || 'none');
  });

  const onSourceChange = React.useCallback((target) => {
    const source = sources.find((s) => s.value === target.value);
    setSelectedSource(source);
    setSelectedMetric(source.metrics[0].value);
  });

  const onMetricChange = React.useCallback((target) => {
    setSelectedMetric(target.value);
  });

  React.useEffect(() => {
    if (!metricCode) return;
    if (!sources) return;
    if (!selectedSource || !selectedMetric) loadSelectedSource();
  });

  return selectedSource && selectedMetric && (
    <>
      <Col xs={6} sm>
        <Select
          id="stat"
          formId={formId}
          label={translator.get('general.statistic')}
          defaultValue={stat}
          options={plotOptions.plotStatOptions}
        />
      </Col>
      <Spacer size={0} block />
      <Col sm={6}>
        <Select
          id="source"
          formId={formId}
          label={translator.get('titles.location')}
          defaultValue={selectedSource.value}
          onChange={onSourceChange}
          options={sources}
        />
      </Col>
      <Col sm={6}>
        <Select
          id="metric"
          formId={formId}
          label={translator.get('titles.parameter')}
          defaultValue={selectedMetric}
          onChange={onMetricChange}
          options={selectedSource.metrics}
        />
      </Col>
    </>
  );
}

export function AddPlotDataRowButton({ plotKey, setPlotDataDetails }) {
  const translator = new Translator();
  const plot = reportPlotSelector(plotKey, ['updatePath']);
  const updatePlot = reportPlotDispatch(plotKey);
  const updateError = reportErrorDispatch();

  const addPlotData = React.useCallback(() => {
    patchJSON(
      plot.updatePath,
      { add_object: { object_type: 'plot_data' } },
      (response) => {
        updatePlot(response.object);
        setPlotDataDetails(response.added);
      },
      (error) => updateError(error.message),
    );
  });

  return (
    <tr>
      <td className="px-3 text-center">
        <Button size="xs" variant="primary" onClick={addPlotData} style={{ minWidth: '150px' }} withIcon>
          <Icon name="plus" />
          {translator.get('general.add').toUpperCase()}
        </Button>
      </td>
    </tr>
  );
}

export function ReportPlotDataForm({
  plotKey, plotDataDetails, setPlotDataDetails, formId, children,
}) {
  const translator = new Translator();
  const plotOptions = reportPlotOptionsSelector();
  const updateError = reportErrorDispatch();
  const report = reportSelector(['locationCode']);
  const plot = reportPlotSelector(plotKey, ['updatePath']);
  const updatePlot = reportPlotDispatch(plotKey);
  const [dataType, setDataType] = React.useState(plotDataDetails.data.type);
  const [sources, setSources] = React.useState(null);
  const [variables, setVariables] = React.useState(plotDataDetails.data.variables || []);
  const [selectedVariable, setSelectedVariable] = React.useState(null);
  const hasVariables = dataType === 'equation';
  const prepareDataForSubmission = React.useCallback((parsedForm) => {
    const newFormData = parsedForm;

    if (hasVariables) newFormData[formId].variables = variables;

    return newFormData;
  });

  const removePlotData = React.useCallback(() => {
    patchJSON(
      plot.updatePath,
      { remove_object: { object_type: 'plot_data', object_id: plotDataDetails.id } },
      (response) => {
        setPlotDataDetails(null);
        updatePlot(response.object);
      },
      (error) => updateError(error.message),
    );
  });

  React.useEffect(() => {
    if (!sources) loadSources(report, plotOptions, setSources, updateError);
  });

  return (
    <>
      <ReportPlotFormWrapper
        formId={formId}
        prepareData={prepareDataForSubmission}
        hidden={!!selectedVariable}
        onRemove={removePlotData}
        afterSave={() => setPlotDataDetails(null)}
      >
        { children }
        <Col xs={6} sm>
          <Select
            id="data_type"
            formId={formId}
            label={translator.get('general.type')}
            defaultValue={dataType}
            onChange={(target) => setDataType(target.value)}
            options={plotOptions.plotDataTypeOptions}
          />
        </Col>
        <Col xs={6} sm>
          <Select
            id="interval"
            formId={formId}
            label={translator.get('general.sampling')}
            defaultValue={plotDataDetails.data.interval || 'raw'}
            options={plotOptions.plotStatIntervalOptions}
          />
        </Col>
        <Col>
          <Select
            id="precision"
            formId={formId}
            label={translator.get('general.decimal-cases')}
            defaultValue={plotDataDetails.data.precision || 'auto'}
            options={['auto', 0, 1, 2, 3, 4, 5, 6, 7, 8, 9]}
          />
        </Col>
        { !hasVariables && (
          <ReportDataSourceSelection
            formId={formId}
            sources={sources}
            metricCode={plotDataDetails.data.code || 'default'}
            stat={plotDataDetails.data.stat}
          />
        )}
        <Col xs={12}>
          <Input
            id="calculation"
            formId={formId}
            label={translator.get('general.calculation')}
            defaultValue={plotDataDetails.data.calculation || plotDataDetails.data.equation}
            placeholder="(x * 1.0) + 0.0"
          />
        </Col>
        { hasVariables && (
          <ReportDataVariableList
            formId={formId}
            sources={sources}
            variables={variables}
            selectedVariable={selectedVariable}
            setSelectedVariable={setSelectedVariable}
          />
        )}
      </ReportPlotFormWrapper>
      { selectedVariable && (
        <ReportDataVariableForm
          parentFormId={formId}
          sources={sources}
          variables={variables}
          setVariables={setVariables}
          selectedVariable={selectedVariable}
          setSelectedVariable={setSelectedVariable}
        />
      )}
    </>
  );
}
