/* globals document navigator jQuery ClipboardItem $ */
/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
/* eslint-disable array-callback-return */
/* eslint-disable no-loop-func */

import Chart from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import moment from 'moment';
import { isBoolean, union } from 'lodash';
import {} from '../vendor/chart-zoom';
import {} from '../vendor/chart-crosshair';

global.dux_plot_bg_fill = 'white';

Chart.plugins.register(ChartDataLabels);

Chart.plugins.register({
  beforeDraw(chartInstance) {
    const ctx = chartInstance.chart.ctx;
    ctx.fillStyle = global.dux_plot_bg_fill;
    ctx.fillRect(0, 0, chartInstance.chart.width, chartInstance.chart.height);
  },
});

export const DuxPlot = function (el, type) {
  const self = this;

  self.colors = {
    0: '#1f77b4', // muted blue
    1: '#ff7f0e', // safety orange
    2: '#2ca02c', // cooked asparagus green
    3: '#d62728', // brick red
    4: '#9467bd', // muted purple
    5: '#8c564b', // chestnut brown
    6: '#e377c2', // raspberry yogurt pink
    7: '#7f7f7f', // middle gray
    8: '#bcbd22', // curry yellow-green
    9: '#17becf', // blue-teal
    10: '#aec7e8', // soft blue
    11: '#ffbb78', // soft orange
    12: '#98df8a', // soft green
    13: '#ff9896', // soft red
    14: '#c5b0d5', // soft purple
    15: '#c49c94', // soft brown
    16: '#f7b6d2', // soft pink
    17: '#c7c7c7', // light gray
    18: '#dbdb8d', // soft yellow-green
    19: '#9edae5', // soft teal
    blue: 'rgb(54, 162, 235)',
    green: 'rgb(75, 192, 192)',
    yellow: 'rgb(255, 205, 86)',
    orange: 'rgb(255, 159, 64)',
    red: 'rgb(255, 99, 132)',
    purple: 'rgb(153, 102, 255)',
    white: '#ffffff',
    grey: '#c3c3c3',
    black: '#000000',
  };

  const options = function () {
    if (self.type === 'doughnut' || self.type === 'pie') {
      return {
        responsive: true,
        maintainAspectRatio: false,
        layout: {
          padding: 25,
        },
        legend: {
          display: false,
          position: 'bottom',
        },
        animation: {
          duration: 0,
          animateRotate: true,
          animateScale: false,
        },
        plugins: {
          crosshair: false,
          zoom: {},
          datalabels: {
            formatter: (val, ctx) => {
              if (!val) return null;
              return ctx.chart.data.labels[ctx.dataIndex];
            },
            anchor: 'end',
            align: 'end',
            backgroundColor: (ctx) => { // eslint-disable-line arrow-body-style
              return ctx.chart.data.datasets[ctx.datasetIndex].backgroundColor[ctx.dataIndex];
            },
            borderRadius: 3,
            color: 'white',
          },
        },
      };
    }
    return {
      responsive: true,
      responsiveAnimationDuration: 0,
      maintainAspectRatio: false,
      animation: {
        duration: 0,
      },
      elements: {
        line: {
          tension: false,
        },
      },
      tooltips: {
        mode: 'nearest',
        position: 'nearest',
        intersect: false,
        caretPadding: 0,
        caretSize: 5,
      },
      legend: {
        display: false,
        position: 'bottom',
      },
      hoverMode: 'index',
      scales: {
        xAxes: [],
        yAxes: [],
      },
      plugins: {
        crosshair: {},
        zoom: {},
        datalabels: false,
      },
    };
  };

  const xaxis = function (type) {
    type = typeof type === 'undefined' ? 'time' : type;
    if (type === 'time') {
      return {
        type: 'time',
        stacked: false,
        offset: true,
        ticks: {
          maxRotation: 0,
          autoSkipPadding: 10,
          major: {
            enabled: true,
          },
        },
        time: {
          unit: false,
          parser: 'YYYY-MM-DD HH:mm:ss',
          tooltipFormat: 'll HH:mm:ss',
          displayFormats: {
            millisecond: 'ss',
            second: 'mm:ss',
            minute: 'HH:mm',
            hour: 'HH:mm',
            day: 'MMM D',
            week: 'll',
            month: 'MMM YYYY',
            quarter: 'MMM YYYY',
          },
        },
        scaleLabel: {
          display: false,
          labelString: false,
        },
        gridLines: {
          drawOnChartArea: true,
          offsetGridLines: false,
        },
      };
    } if (type === 'category') {
      return {
        type: 'category',
        stacked: false,
        offset: true,
        scaleLabel: {
          display: false,
          labelString: false,
        },
        gridLines: {
          drawOnChartArea: true,
          offsetGridLines: false,
        },
      };
    }
    return {
      type: 'linear',
      stacked: false,
      offset: true,
      ticks: {
        type: 'linear',
        min: 0,
        max: 0,
        stepSize: 1,
        maxRotation: 0,
        autoSkipPadding: 10,
      },
      scaleLabel: {
        display: false,
        labelString: false,
      },
      gridLines: {
        drawOnChartArea: true,
        offsetGridLines: false,
      },
    };
  };

  const yaxis = function (title, id, position) {
    return {
      id,
      type: 'linear',
      display: true,
      stacked: false,
      position,
      scaleLabel: {
        display: title !== null,
        labelString: title,
      },
      ticks: {
        suggestedMin: null,
        suggestedMax: null,
      },
      gridLines: {
        drawOnChartArea: true,
      },
    };
  };

  const dataset = function (title, color, type, axis) {
    const isStacked = self.chart.options.scales.yAxes.filter((a) => a.id === axis)[0].stacked;
    let bgcolor = color;
    let fill = false;

    if (type === 'area') {
      type = 'line';
      fill = true;
    }
    if (bgcolor.includes('#') && bgcolor.length < 8 && !isStacked) {
      bgcolor += 'B0';
    } else if (bgcolor.includes('rgb(')) {
      bgcolor = bgcolor.replace('rgb(', 'rgba(');
      bgcolor = bgcolor.replace(')', ', 0.69)');
    }
    return {
      yAxisID: axis,
      label: title,
      type,
      fill,
      steppedLine: false,
      backgroundColor: bgcolor,
      borderColor: color,
      borderWidth: 2,
      pointRadius: 0,
      pointHoverRadius: 0,
      data: [],
    };
  };

  const parseData = function (data) {
    const rows = [];
    if (typeof data.x !== 'undefined') {
      for (let i = 0; i < data.x.length; i += 1) {
        rows.push({
          x: data.x[i],
          y: data.y[i],
        });
      }
    }
    return rows;
  };

  const zoom = function () {
    return {
      pan: {
        enabled: true,
        mode: 'x',
        speed: 10,
      },
      zoom: {
        enabled: true,
        drag: false,
        mode: 'x',
        speed: 0.1,
      },
    };
  };

  const crosshair = function () {
    return {
      line: {
        enabled: true,
        color: '#3c3c3c',
        width: 1,
        dashPattern: [6, 3],
      },
    };
  };

  this.element = typeof el === 'undefined' ? null : $(el);

  this.type = typeof type === 'undefined' ? 'time' : type;

  this.setup = function (el) {
    if (el) self.element = $(el);

    if (self.type === 'doughnut' || self.type === 'pie') {
      self.chart = new Chart(self.element, {
        type: self.type,
        data: {
          datasets: [],
          labels: [],
        },
        options: options(),
      });
    } else {
      self.chart = new Chart(self.element, {
        type: 'line',
        data: {
          datasets: [],
        },
        options: options(),
      });
      self.chart.options.scales.xAxes = [];
      self.chart.options.scales.yAxes = [];
      if (self.type === 'time') {
        self.addX('time');
      } else if (self.type === 'line') {
        self.addX('line');
      } else {
        self.addX('category');
      }
    }
  };

  this.destroy = function () {
    self.chart.destroy();
  };

  this.reset = function () {
    self.destroy();
    self.setup();
  };

  this.setType = function (type) {
    self.type = type || 'time';
  };

  this.addX = function (type, options) {
    type = typeof type === 'undefined' ? 'time' : type;
    options = typeof options === 'undefined' ? {} : options;
    self.chart.options.scales.xAxes.push(xaxis(type));
    jQuery.extend(true, self.chart.options.scales.xAxes[0], options);
  };

  this.setXRange = function (min, max) {
    if (typeof min === 'undefined' || typeof max === 'undefined') {
      max = -Infinity;
      min = Infinity;

      for (let i = 0; i < self.chart.data.datasets.length; i += 1) {
        self.chart.data.datasets[i].data.filter((d) => {
          const value = self.type === 'time' ? moment(d.x) : d.x;
          if (value > max) max = value;
          if (value < min) min = value;
        });
      }
    }

    if (min === Infinity) min = 0;
    if (max === -Infinity) max = 1;

    if (self.type === 'time') {
      self.chart.options.scales.xAxes[0].ticks.min = min;
      self.chart.options.scales.xAxes[0].ticks.max = max;
    } else {
      delete self.chart.options.scales.xAxes[0].ticks.min;
      delete self.chart.options.scales.xAxes[0].ticks.max;
      self.chart.options.scales.xAxes[0].ticks.suggestedMin = min;
      self.chart.options.scales.xAxes[0].ticks.suggestedMax = max;
    }
  };

  this.setX = function (options) {
    type = typeof type === 'undefined' ? {} : options;
    if (options?.time?.unit) self.chart.options.tooltips.mode = 'index';
    jQuery.extend(true, self.chart.options.scales.xAxes[0], options);
  };

  this.addY = function (title, position, options, id) {
    position = typeof position === 'undefined' ? 'left' : position;
    options = typeof options === 'undefined' ? {} : options;
    id = typeof id === 'undefined' ? 'axis-1' : id;
    self.chart.options.scales.yAxes.push(jQuery.extend(true, yaxis(title, id, position), options));
  };

  this.setYRange = function (min, max, gap, id) {
    let index = 0;

    id = typeof id === 'undefined' ? 'axis-1' : id;
    gap = typeof gap === 'undefined' ? 0.1 : gap;

    for (let i = 0; i < self.chart.options.scales.yAxes.length; i += 1) {
      if (self.chart.options.scales.yAxes[i].id === id) {
        index = i;
      }
    }

    if (typeof min === 'undefined' || typeof max === 'undefined') {
      let axis_max = -Infinity;
      let axis_min = Infinity;
      for (let i = 0; i < self.chart.data.datasets.length; i += 1) {
        if (self.chart.data.datasets[i].yAxisID === id) {
          self.chart.data.datasets[i].data.filter((d) => {
            if (!d.y) return;
            if (d.y > axis_max) {
              axis_max = d.y;
            }
            if (d.y < axis_min) {
              axis_min = d.y;
            }
          });
          if (self.chart.data.datasets[i].type === 'bar') {
            if (axis_min > 0) {
              axis_min = 0;
            }
          }
        }
      }
      const bound_delta = (axis_max - axis_min) * gap;
      axis_max += bound_delta;
      if ((axis_min - bound_delta < 0 && axis_min >= 0)) {
        axis_min = 0;
      } else {
        axis_min -= bound_delta;
      }
      if (axis_max === axis_min) {
        axis_max = axis_min + 1;
      }
      if (typeof min === 'undefined') {
        min = axis_min;
      }
      if (typeof max === 'undefined') {
        max = axis_max;
      }
    }

    if (min === Infinity) {
      min = 0;
    }
    if (max === -Infinity) {
      max = 1;
    }

    delete self.chart.options.scales.yAxes[index].ticks.min;
    delete self.chart.options.scales.yAxes[index].ticks.max;
    self.chart.options.scales.yAxes[index].ticks.suggestedMin = min;
    self.chart.options.scales.yAxes[index].ticks.suggestedMax = max;
  };

  this.setY = function (options, id) {
    id = typeof id === 'undefined' ? 'axis-1' : id;
    let index = 0;
    for (let i = 0; i < self.chart.options.scales.yAxes.length; i += 1) {
      if (self.chart.options.scales.yAxes[i].id === id) {
        index = i;
      }
    }
    jQuery.extend(true, self.chart.options.scales.yAxes[index], options);
  };

  this.addDataset = function (title, color, type, options, axis) {
    type = typeof type === 'undefined' ? 'line' : type;
    options = typeof options === 'undefined' ? {} : options;
    axis = typeof axis === 'undefined' ? 'axis-1' : axis;
    self.chart.data.datasets.push(jQuery.extend(true, dataset(title, color, type, axis), options));
    return self.chart.data.datasets.length - 1;
  };

  this.addPieDataset = function (title, options) {
    self.chart.data.datasets.push(jQuery.extend(
      true,
      {
        label: title,
        data: [],
        backgroundColor: [],
      },
      options,
    ));
  };

  this.setDataset = function (options, index) {
    index = typeof index === 'undefined' ? 0 : index;
    jQuery.extend(true, self.chart.data.datasets[index], options);
  };

  this.setData = function (data, index) {
    if (!data) {
      data = { x: [], y: [] };
    }

    // data: {x: [], y: [], color: []}
    index = typeof index === 'undefined' ? 0 : index;
    if (self.type === 'doughnut' || self.type === 'pie' || self.type === 'category') {
      self.chart.data.labels = union(self.chart.data.labels, data.x);
      self.chart.data.datasets[index].data = [];
      self.chart.data.labels.forEach((label) => {
        const dataIndex = data.x.indexOf(label);
        self.chart.data.datasets[index].data.push(dataIndex === -1 ? 0 : data.y[dataIndex]);
      });

      if (self.type !== 'category') {
        self.chart.data.datasets[index].backgroundColor = [];
        if (typeof data.color === 'undefined') {
          for (let i = 0; i < self.chart.data.labels.length; i += 1) {
            self.chart.data.datasets[index].backgroundColor.push(self.colors[i]);
          }
        } else {
          self.chart.data.datasets[index].backgroundColor = data.color;
        }
      }
    } else {
      self.chart.data.datasets[index].data = parseData(data);
    }
  };

  this.setOptions = function (options) {
    jQuery.extend(true, self.chart.options, options);
  };

  this.setLineTension = function (tension) {
    self.chart.options.elements.line.tension = isBoolean(tension) ? 0.4 : tension;
  };

  this.enableZoom = function (options) {
    options = typeof options === 'undefined' ? {} : options;
    self.chart.options.plugins.zoom = jQuery.extend(true, zoom(), options);
  };

  this.enableCrosshair = function (options) {
    options = typeof options === 'undefined' ? {} : options;
    self.chart.options.tooltips.position = 'average';
    self.chart.options.plugins.crosshair = jQuery.extend(true, crosshair(), options);
  };

  this.enableLegend = function (position) {
    self.chart.options.legend.display = true;
    if (typeof position !== 'undefined') {
      self.chart.options.legend.position = position;
    }
  };

  this.apply = function () {
    self.chart.update();
  };

  this.download = function (name, type) {
    const hiddenElement = document.createElement('a');
    hiddenElement.href = self.element.get(0).toDataURL(`image/${type}`, 1);
    hiddenElement.target = '_blank';
    hiddenElement.download = name;
    hiddenElement.click();
  };

  this.clipboard = function () {
    self.element.get(0).toBlob((blob) => {
      const item = new ClipboardItem({ 'image/png': blob });
      navigator.clipboard.write([item]);
    });
  };

  this.zoomOut = () => {
    self.chart.options.scales.yAxes.forEach((axis) => {
      self.setYRange(undefined, undefined, undefined, axis.id);
    });
    self.setXRange(undefined, undefined);
    self.apply();
  };

  if (this.element) this.setup();
};
