import { merge } from 'lodash';
import { globalObject } from './global_object';

export const Project = function () {
  const self = this;

  this.set = (newValues) => {
    merge(self, globalObject.project, newValues);
  };

  this.reload = () => {
    merge(self, globalObject.project);
  };

  this.userCan = (permission) => !!globalObject.permissions[permission];

  return this.reload();
};
