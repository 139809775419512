/* globals $ */

import * as React from 'react';
import { uniq } from 'lodash';
import {
  Row, Form as BsForm, InputGroup, Badge, Button, Col,
} from 'react-bootstrap';
import moment from 'moment';
import { Icon } from './Icon.jsx';
import { randomHex } from '../../plugins/dux-utils';

export function Checkbox(props) {
  const {
    id,
    formId,
    label,
    block = false,
    button = false,
    validation = null,
    className = '',
    onLoad,
    ...forwarded
  } = props;

  const classes = [className];
  const parsedId = formId ? `${formId}_${id}` : id;
  const parsedName = formId ? `${formId}[${id}]` : id;
  const self = React.createRef();

  if (block) classes.push('form-check-block');
  if (button) classes.push('form-check-btn');

  React.useEffect(() => {
    const obj = $(self.current);
    if (onLoad) onLoad(obj);
    if (validation) obj.duxValidate();
  });

  return (
    <BsForm.Check
      ref={self}
      type="checkbox"
      id={parsedId}
      name={parsedName}
      label={label}
      className={classes.join(' ').trim()}
      data-validate={validation}
      {...forwarded}
    />
  );
}

export function Select(props) {
  const {
    id,
    label,
    formId = '',
    options = [],
    search = false,
    tags = false,
    multiple = false,
    placeholder = null,
    validation = null,
    defaultValue = null,
    onChange = null,
    readOnly = false,
    ...forwarded
  } = props;

  const self = React.createRef();
  const parsedId = formId ? `${formId}_${id}` : id;
  let parsedName = formId ? `${formId}[${id}]` : id;
  const parsedOptions = options.map((option) => {
    if (typeof option === 'object') return option;

    return { name: option, value: option };
  });

  if (multiple) parsedName += '[]';

  React.useEffect(() => {
    const obj = $(self.current);
    const selectOptions = {};
    const minWidth = parseInt(
      obj.css('min-width').replace(/px|em|ex|%|in|cm|mm|pt|pc/i, ''),
      10,
    );

    if (validation) obj.duxValidate();
    if (!search) selectOptions.minimumResultsForSearch = -1;
    if (tags) selectOptions.tags = true;
    if (minWidth === 0) selectOptions.width = '100%';
    if (placeholder) selectOptions.placeholder = placeholder;
    if (readOnly) selectOptions.disabled = true;

    const parentForm = $(`#${parsedId}`).closest('form');
    if (parentForm[0]) selectOptions.dropdownParent = parentForm;

    obj.off('change');
    obj.select2(selectOptions);
    if (onChange) {
      obj.change((e) => onChange(e.target));
    }
  });

  return (
    <BsForm.Group controlId={parsedId} className={readOnly ? 'select2-readonly' : null}>
      <BsForm.Label>{label}</BsForm.Label>
      <BsForm.Control
        ref={self}
        as="select"
        name={parsedName}
        multiple={multiple}
        data-validate={validation}
        defaultValue={defaultValue}
        onChange={onChange}
        {...forwarded}
      >
        {parsedOptions.map((type) => (
          <option key={type.value} value={type.value}>
            {type.name}
          </option>
        ))}
      </BsForm.Control>
    </BsForm.Group>
  );
}

export function Input(props) {
  const {
    id,
    formId,
    label,
    onLoad,
    validation = null,
    ...forwarded
  } = props;

  const self = React.createRef();
  const parsedId = formId ? `${formId}_${id}` : id;
  const parsedName = formId ? `${formId}[${id}]` : id;

  React.useEffect(() => {
    const obj = $(self.current);
    if (onLoad) onLoad(obj);
    if (validation) obj.duxValidate();
  });

  return (
    <BsForm.Group controlId={parsedId}>
      <BsForm.Label>{label}</BsForm.Label>
      <BsForm.Control
        ref={self}
        name={parsedName}
        data-validate={validation}
        {...forwarded}
      />
    </BsForm.Group>
  );
}

export const HiddenInput = React.forwardRef((props, ref) => {
  const {
    id,
    formId,
    ...forwarded
  } = props;

  const parsedId = formId ? `${formId}_${id}` : id;
  const parsedName = formId ? `${formId}[${id}]` : id;

  return (
    <BsForm.Control
      ref={ref}
      id={parsedId}
      name={parsedName}
      hidden
      readOnly
      {...forwarded}
    />
  );
});

export function FileInput(props) {
  const {
    id,
    formId,
    label,
    onLoad,
    validation = null,
    className = '',
    multiple = false,
    onSelection = null,
    accept = '*/*',
    ...forwarded
  } = props;

  const classes = [className, 'cursor-pointer'];
  const self = React.createRef();
  const fileRef = React.createRef();
  const parsedId = formId ? `${formId}_${id}` : id;
  const parsedName = formId ? `${formId}[${id}]` : id;

  React.useEffect(() => {
    const obj = $(self.current);
    if (onLoad) onLoad(obj);
    if (validation) obj.duxValidate();
  });

  const triggerFileInput = React.useCallback(() => {
    fileRef.current.click();
  });

  const fileSelected = React.useCallback(() => {
    const files = fileRef.current.files;
    let inputText = '';

    if (files.length > 1) {
      inputText += `${files.length} files:  `;
    }
    for (let i = 0; i < files.length; i += 1) {
      inputText += `${files[i].name}  `;
    }

    if (onSelection) onSelection(files);

    self.current.value = inputText;
  });

  return (
    <BsForm.Group controlId={parsedId}>
      <BsForm.Label>{label}</BsForm.Label>
      <InputGroup onClick={triggerFileInput} className="cursor-pointer">
        <InputGroup.Prepend>
          <InputGroup.Text>
            <Icon name="upload" />
          </InputGroup.Text>
        </InputGroup.Prepend>
        <BsForm.Control
          ref={self}
          className={classes.join(' ').trim()}
          data-validate={validation}
          readOnly
          {...forwarded}
        />
        <BsForm.File
          name={parsedName}
          ref={fileRef}
          onChange={fileSelected}
          multiple={multiple}
          accept={accept}
          hidden
        />
      </InputGroup>
    </BsForm.Group>
  );
}

export const DateRangePicker = React.memo((props) => {
  const {
    id = null,
    formId = null,
    label = null,
    onLoad = null,
    onChange = null,
    now = moment().startOf('minute'),
    timeIncrement = 5,
    endDate = null,
    startDate = null,
    timePicker = true,
    singleDatePicker = false,
    format = timePicker ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD',
    opens = 'left',
    className = '',
    validation = null,
    variant = 'primary',
    iconClass = 'text-white',
    textClass = 'fs-12 text-center',
    width = '100%',
    fixedRange = false,
    ranges = null,
    ...forwarded
  } = props;

  const classes = [className, 'cursor-pointer', 'border-0', textClass];
  const self = React.createRef();
  const parsedId = formId ? `${formId}_${id}` : id;
  const parsedName = formId ? `${formId}[${id}]` : id;
  const parsedNow = now.clone().subtract(now.minute() % timeIncrement, 'minutes');
  const parsedStartDate = startDate || (singleDatePicker ? parsedNow : parsedNow.clone().subtract(24, 'hours'));
  const parsedEndDate = endDate || parsedNow;
  const config = {
    timePicker,
    timePicker24Hour: true,
    timePickerIncrement: timeIncrement,
    singleDatePicker,
    startDate: parsedStartDate,
    endDate: parsedEndDate,
    opens,
    alwaysShowCalendars: !fixedRange,
    showCustomRangeLabel: !fixedRange,
    locale: { format },
  };

  if (!singleDatePicker) {
    config.ranges = ranges || {
      '06 hours': [parsedEndDate.clone().subtract(6, 'hours'), parsedEndDate],
      '12 hours': [parsedEndDate.clone().subtract(12, 'hours'), parsedEndDate],
      '24 hours': [parsedEndDate.clone().subtract(24, 'hours'), parsedEndDate],
      '07 Days': [parsedEndDate.clone().subtract(7, 'days'), parsedEndDate],
      '30 Days': [parsedEndDate.clone().subtract(30, 'days'), parsedEndDate],
      '03 months': [parsedEndDate.clone().subtract(3, 'months'), parsedEndDate],
      '06 months': [parsedEndDate.clone().subtract(6, 'months'), parsedEndDate],
    };
  }

  React.useEffect(() => {
    const obj = $(self.current);
    obj.daterangepicker(config);

    if (onLoad) onLoad(obj);
    if (validation) obj.duxValidate();

    obj.closest('.modal').removeAttr('tabindex');

    obj.on('apply.daterangepicker', (ev, picker) => {
      if (onChange) onChange(picker.startDate, picker.endDate);
    });
  });

  return (
    <BsForm.Group controlId={parsedId} style={{ width }}>
      { label && (
        <BsForm.Label>{label}</BsForm.Label>
      )}
      <InputGroup className={`cursor-pointer overflow-hidden rounded-small border border-${variant}`}>
        <InputGroup.Prepend className={`bg-${variant} `}>
          <InputGroup.Text className={`${iconClass} bg-${variant}`}>
            <Icon name="calendar" />
          </InputGroup.Text>
        </InputGroup.Prepend>
        <BsForm.Control
          ref={self}
          name={parsedName}
          className={classes.join(' ').trim()}
          data-validate={validation}
          {...forwarded}
        />
      </InputGroup>
    </BsForm.Group>
  );
});

export function ListEditor(props) {
  const {
    id,
    formId,
    label,
    className = '',
    defaultValues = [],
    bordered = true,
  } = props;

  const classes = ['p-2', className];
  const tempId = `list_editor_${randomHex()}`;
  const [updatedValues, setUpdatedValues] = React.useState(defaultValues);

  if (bordered) classes.push('border');

  const addValue = React.useCallback((input) => {
    const value = input.value.trim();

    if (value) {
      setUpdatedValues(uniq([...updatedValues, value]));
      // eslint-disable-next-line no-param-reassign
      input.value = '';
    }
  });

  const removeValue = React.useCallback((value) => {
    setUpdatedValues(updatedValues.filter((v) => v !== value));
  });

  const inputGroupOnClick = React.useCallback((e) => {
    const inputGroup = e.target.closest('.input-group');
    const input = inputGroup.querySelector('input');
    addValue(input);
  });

  const inputKeyDown = React.useCallback((e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      addValue(e.target);
    }
  });

  return (
    <>
      <HiddenInput id={id} formId={formId} value={JSON.stringify(updatedValues)} />
      <BsForm.Label>{label}</BsForm.Label>
      <div className={classes.join(' ')}>
        <Row className="gutter-1 align-items-center">
          { updatedValues.map((value) => (
            <Col xs="auto" key={value}>
              <Badge variant="light" className="p-2 d-flex align-items-center">
                { value }
                <Icon name="times" className="fs-12 ml-2 cursor-pointer" onClick={() => removeValue(value)} />
              </Badge>
            </Col>
          ))}
          <Col xs="auto">
            <InputGroup className="border rounded-small">
              <BsForm.Control
                id={tempId}
                name={tempId}
                onKeyDown={inputKeyDown}
                className="border-0"
              />
              <InputGroup.Append onClick={inputGroupOnClick}>
                <Button variant="light border-left">
                  <Icon name="plus fs-12" />
                </Button>
              </InputGroup.Append>
            </InputGroup>
          </Col>
        </Row>
      </div>
    </>
  );
}

export const Form = React.forwardRef((props, ref) => {
  const {
    children,
    validate = false,
    withBorders = false,
    authenticityToken = null,
    className = '',
    method = 'post',
    ...forwarded
  } = props;

  const classes = [className];
  const self = ref || React.createRef();

  if (withBorders) classes.push('form-with-borders');

  React.useEffect(() => {
    const obj = $(self.current);
    if (validate) obj.duxValidate();
  });

  return (
    <BsForm
      ref={self}
      className={classes.join(' ').trim()}
      method={method === 'get' ? 'get' : 'post'}
      noValidate
      {...forwarded}
    >
      <HiddenInput name="_method" value={method} />
      { authenticityToken
        && <HiddenInput id="authenticity_token" defaultValue={authenticityToken} /> }
      <Row className="gutter-1">{children}</Row>
    </BsForm>
  );
});
