import * as React from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { GlobalWrapper } from '../GlobalWrapper.jsx';
import { Dashbox, DashboxBody, DashboxTitle } from '../../duxfront/duxdash/components/Dashbox.jsx';
import { Translator } from '../../global/translator';
import { Button } from '../../duxfront/duxstrap/components/Button.jsx';
import { Icon } from '../../duxfront/duxstrap/components/Icon.jsx';
import { Spacer } from '../../duxfront/duxstrap/components/Spacer.jsx';
import { JSONEditor } from '../../duxfront/duxdash/components/JSONEditor.jsx';
import { TextEditor } from '../../duxfront/duxdash/components/TextEditor.jsx';
import { Project } from '../../global/project';
import { Authenticity } from '../../global/authenticity';
import { Form, Input } from '../../duxfront/duxstrap/components/Form.jsx';

function ProjectDetailsBox() {
  const translator = new Translator();
  const project = new Project();
  const [showModal, setShowModal] = React.useState(false);
  const canEdit = project.userCan('manage_project');

  const launchModal = React.useCallback(() => {
    if (!canEdit) return;

    setShowModal(true);
  });

  return (
    <Dashbox>
      <DashboxTitle title={translator.get('general.details')}>
        { canEdit && (
          <>
            <Button rounded icon size="sm" variant="outline-primary" onClick={launchModal}>
              <Icon name="pencil" />
            </Button>
            <ProjectUpdateModal show={showModal} setShow={setShowModal} />
          </>
        )}
      </DashboxTitle>
      <DashboxBody>
        <Row className="my-0 gutter-1">
          <Col md={6}>
            <div className="text-gray-500 fs-10 text-uppercase font-weight-bold">
              {translator.get('general.title')}
            </div>
            <div className="fs-16">{project.title}</div>
          </Col>
          <Col md={6}>
            <div className="text-gray-500 fs-10 text-uppercase font-weight-bold">
              {translator.get('general.code')}
            </div>
            <div className="fs-16">{project.code}</div>
          </Col>
          <Col xs={12}>
            <div className="text-gray-500 fs-10 text-uppercase font-weight-bold">
              {translator.get('general.details')}
            </div>
            {/* eslint-disable-next-line react/no-danger */}
            <div className="fs-16" dangerouslySetInnerHTML={{ __html: project.details }} />
          </Col>
        </Row>
      </DashboxBody>
    </Dashbox>
  );
}

function ProjectUpdateModal({
  show, setShow,
}) {
  const translator = new Translator();
  const project = new Project();
  const authenticityToken = new Authenticity().token();
  const self = React.createRef();
  const formId = 'project';

  const closeModal = React.useCallback(() => setShow(null));

  return project && (
    <Modal centered size="lg" show={show} onHide={closeModal}>
      <Modal.Header closeButton onHide={closeModal}>
        <Modal.Title>
          {translator.get('general.update')}
          { ' ' }
          {translator.get('titles.project')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body ref={self} className="pt-2 px-3">
        <Form
          id={formId}
          action={project.updatePath}
          method="patch"
          authenticityToken={authenticityToken}
          validate
          withBorders
        >
          <Col sm={12}>
            <Input
              id="title"
              formId={formId}
              label={translator.get('general.title')}
              defaultValue={project.title}
              placeholder="Department X"
              validation="required"
            />
          </Col>
          <Col sm={12}>
            <TextEditor
              id="details"
              formId={formId}
              label={translator.get('general.details')}
              defaultValue={project.details}
              placeholder="Lorem Ipsum"
            />
          </Col>
          <Col sm={12}>
            <JSONEditor
              collapsed
              formId={formId}
              label={translator.get('general.email_notifications')}
              inputId="configs"
              json={project.config}
              style={{ maxHeight: '40vh' }}
              forceSnakeCase
            />
          </Col>
          <Spacer block />
          <Col sm={6}>
            <Button size="sm" variant="gray-200" onClick={closeModal} withIcon block>
              <Icon name="times" />
              { translator.get('general.cancel').toUpperCase() }
            </Button>
          </Col>
          <Col sm={6}>
            <Button size="sm" variant="primary" type="submit" withIcon block>
              <Icon name="check" />
              { translator.get('general.save').toUpperCase() }
            </Button>
          </Col>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export function ProjectDetails(props) {
  return (
    <GlobalWrapper {...props}>
      <ProjectDetailsBox />
    </GlobalWrapper>
  );
}
