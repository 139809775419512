import * as React from 'react';
import { Col, Modal, Table } from 'react-bootstrap';
import { GlobalWrapper } from '../GlobalWrapper.jsx';
import { Translator } from '../../global/translator';
import { Button } from '../../duxfront/duxstrap/components/Button.jsx';
import { Icon } from '../../duxfront/duxstrap/components/Icon.jsx';
import { Spacer } from '../../duxfront/duxstrap/components/Spacer.jsx';
import { LocationLink } from '../../duxfront/duxstrap/components/LocationLink.jsx';
import { globalDispatch, globalSelector } from '../../duxfront/plugins/dux-redux';
import {
  deleteJSON, loadingOverlay, parseForm, patchJSON, postJSON, updateByKey, removeByKey,
} from '../../duxfront/plugins/dux-utils';
import { Project } from '../../global/project';
import { Authenticity } from '../../global/authenticity';
import {
  Form, HiddenInput, Input, Select,
} from '../../duxfront/duxstrap/components/Form.jsx';
import { BootBox } from '../../duxfront/duxstrap/vendor/bootbox';

function LocationObservationInitialLoader({ location, locationObservations }) {
  const updateLocation = globalDispatch('selectedLocation');
  const updateLocationObservations = globalDispatch('locationObservations');

  React.useEffect(() => {
    updateLocation(location);
    updateLocationObservations(locationObservations);
  });

  return null;
}

function LocationObservationsTable() {
  const translator = new Translator();
  const project = new Project();
  const locationObservations = globalSelector('locationObservations');
  const updateSelectedLocationObservation = globalDispatch('selectedLocationObservation');
  const canAdd = project.userCan('manage_location_observations');

  const launchModal = React.useCallback((observation = {}) => {
    updateSelectedLocationObservation(observation);
  });

  return (
    <Table size="sm" className="m-0" hover>
      <tbody>
        { canAdd && (
          <tr key="location-observation-add-button">
            <td className="px-3 text-center" colSpan={2}>
              <Button size="xs" variant="primary" onClick={() => launchModal()} style={{ minWidth: '150px' }} withIcon>
                <Icon name="plus" />
                { translator.get('general.add').toUpperCase() }
              </Button>
            </td>
          </tr>
        )}
        { locationObservations && locationObservations.length > 0
          ? locationObservations.map((observation) => (
            <tr key={observation.code}>
              <td className="px-3">
                <div className="font-weight-accent fs-12 text-secondary">
                  {observation.observationGroup.name}
                </div>
                <div className="font-weight-accent text-primary">
                  {`[${observation.formattedSeverity.toUpperCase()}] - ${observation.reportedReason}`}
                </div>
                <div className="fs-12 text-gray-500">
                  <div>{`${observation.observationType} (${observation.activityType})`}</div>
                  {/* <div>{observation.formattedReportedBy}</div> */}
                  <div>{observation.formattedReportedAt}</div>
                  {observation.latitude && observation.longitude && (
                    <div>
                      <LocationLink
                        latitude={observation.latitude}
                        longitude={observation.longitude}
                        className="text-underlined"
                      >
                        {translator.get('general.view_location')}
                      </LocationLink>
                    </div>
                  )}
                </div>
              </td>
              <td className="px-3 text-right">
                {observation.isOpen ? (
                  <Button size="xs" variant="outline-primary" onClick={() => launchModal(observation)} withIcon>
                    <Icon name="check" />
                    {translator.get('general.close').toUpperCase()}
                  </Button>
                ) : (
                  <>
                    <div className="font-weight-accent text-primary text-uppercase">{observation.formattedStatus}</div>
                    <div className="fs-12 text-gray-500">
                      <div>{observation.formattedClosedBy}</div>
                      <div>{observation.formattedClosedAt}</div>
                    </div>
                  </>
                )}
              </td>
            </tr>
          ))
          : (
            <tr>
              <td className="px-3">
                <p>{translator.get('messages.no-results-found')}</p>
              </td>
            </tr>
          )}
      </tbody>
    </Table>
  );
}

function LocationObservationModal() {
  const translator = new Translator();
  const project = new Project();
  const authenticityToken = new Authenticity().token();
  const self = React.createRef();
  const formId = 'location_observation';
  const selectedLocation = globalSelector('selectedLocation');
  const selectedLocationObservation = globalSelector('selectedLocationObservation');
  const updateSelectedLocationObservation = globalDispatch('selectedLocationObservation');
  const locationObservations = globalSelector('locationObservations');
  const updateLocationObservations = globalDispatch('locationObservations');
  const isUpdate = !!selectedLocationObservation?.code;
  const isClose = isUpdate && !!selectedLocationObservation?.isOpen;
  const [observationGroup, setObservationGroup] = React.useState(null);
  const [observationType, setObservationType] = React.useState(null);
  const [activityType, setActivityType] = React.useState(null);

  const closeModal = React.useCallback(() => {
    updateSelectedLocationObservation(null);
  });

  const submitLocationObservation = React.useCallback((e) => {
    e.preventDefault();

    loadingOverlay(self.current, 'show');

    const httpCall = isUpdate ? patchJSON : postJSON;
    const submissionUrl = (
      selectedLocationObservation?.updatePath || project.locationObservationsPath
    );
    const form = parseForm(e.target);

    httpCall(
      submissionUrl,
      form,
      (data) => {
        loadingOverlay(self.current, 'hide');
        updateLocationObservations(updateByKey(locationObservations, data.object));
        closeModal();
      },
    );
  });

  const destroyLocationObservation = React.useCallback(() => {
    BootBox.confirm({
      size: 'small',
      message: translator.get('messages.are-you-sure'),
      callback: (success) => {
        if (!success) return;

        loadingOverlay(self.current, 'show');

        deleteJSON(
          selectedLocationObservation.updatePath,
          {},
          (data) => {
            loadingOverlay(self.current, 'hide');
            updateLocationObservations(removeByKey(locationObservations, data.object.key));
            closeModal();
          },
        );
      },
    });
  });

  const observationGroupChange = React.useCallback((target) => {
    setObservationGroup(
      project.observationGroups.find((group) => group.code === target.value),
    );
  });

  React.useEffect(() => {
    if (observationGroup) return;

    const group = selectedLocationObservation?.observationGroup || (
      selectedLocation?.observationGroups && selectedLocation?.observationGroups[0]
    );

    if (!group) return;

    const initialObservationType = selectedLocationObservation?.observationType || group.observationTypes[0];
    const initialActivityType = selectedLocationObservation?.activityType || group.activityTypes[0];

    setObservationGroup(group);
    setObservationType(initialObservationType);
    setActivityType(initialActivityType);
  });

  return (
    <Modal centered show={!!selectedLocationObservation} onHide={closeModal}>
      <Modal.Header closeButton onHide={closeModal}>
        <Modal.Title>
          {isClose ? translator.get('general.close') : translator.get('general.add')}
          {' '}
          {translator.get('general.observation')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body ref={self} className="pt-2 px-3">
        <Form
          id={formId}
          onSubmit={submitLocationObservation}
          authenticityToken={authenticityToken}
          validate
          withBorders
        >
          <HiddenInput id="location" formId={formId} defaultValue={selectedLocation?.code} />
          { isClose ? (
            <Col sm={12}>
              <Input
                id="closed_reason"
                formId={formId}
                label={translator.get('general.reason')}
                defaultValue={selectedLocationObservation?.closedReason}
                placeholder="Wild fire"
                validation="required"
              />
            </Col>
          ) : (
            <>
              <Col sm={12}>
                <Select
                  id="severity"
                  formId={formId}
                  label={translator.get('general.severity')}
                  defaultValue={selectedLocationObservation?.identifier}
                  options={project.locationObservationSeverityOptions}
                />
              </Col>
              <Col sm={12}>
                <Select
                  id="observation_group"
                  formId={formId}
                  label={translator.get('general.observation_group')}
                  defaultValue={observationGroup}
                  onChange={observationGroupChange}
                  options={selectedLocation?.observationGroups.map(
                    (group) => ({ name: group.name, value: group.code }),
                  )}
                />
              </Col>
              <Col sm={6}>
                <Select
                  id="observation_type"
                  formId={formId}
                  label={translator.get('general.type')}
                  defaultValue={observationType}
                  onChange={(target) => setObservationType(target.value)}
                  options={observationGroup?.observationTypes}
                />
              </Col>
              <Col sm={6}>
                <Select
                  id="activity_type"
                  formId={formId}
                  label={translator.get('general.activity_type')}
                  defaultValue={activityType}
                  onChange={(target) => setActivityType(target.value)}
                  options={observationGroup?.activityTypes}
                />
              </Col>
              <Col sm={12}>
                <Input
                  id="reported_reason"
                  formId={formId}
                  label={translator.get('general.reason')}
                  defaultValue={selectedLocationObservation?.reportedReason}
                  placeholder="Wild fire"
                  validation="required"
                />
              </Col>
            </>
          )}
          <Spacer block />
          <Col sm={6}>
            {
              isUpdate
                ? (
                  <Button size="sm" variant="danger" onClick={destroyLocationObservation} withIcon block>
                    <Icon name="trash" />
                    { translator.get('general.delete') }
                  </Button>
                )
                : (
                  <Button size="sm" variant="gray-200" onClick={closeModal} withIcon block>
                    <Icon name="times" />
                    { translator.get('general.cancel') }
                  </Button>
                )
            }
          </Col>
          <Col sm={6}>
            <Button size="sm" variant="primary" type="submit" withIcon block>
              <Icon name="check" />
              { translator.get('general.save') }
            </Button>
          </Col>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export function LocationObservations(props) {
  const {
    location,
    locationObservations,
  } = props;

  return (
    <GlobalWrapper {...props}>
      <LocationObservationInitialLoader
        location={location}
        locationObservations={locationObservations}
      />
      <LocationObservationsTable />
      <LocationObservationModal />
    </GlobalWrapper>
  );
}
