import * as React from 'react';
import { Col, Container, Modal } from 'react-bootstrap';
import { sortBy } from 'lodash';
import { GlobalWrapper } from '../GlobalWrapper.jsx';
import { Dashbox, DashboxBody, DashboxTitle } from '../../duxfront/duxdash/components/Dashbox.jsx';
import { Form, HiddenInput } from '../../duxfront/duxstrap/components/Form.jsx';
import { BootBox } from '../../duxfront/duxstrap/vendor/bootbox';
import { Button } from '../../duxfront/duxstrap/components/Button.jsx';
import { Icon } from '../../duxfront/duxstrap/components/Icon.jsx';
import { Spacer } from '../../duxfront/duxstrap/components/Spacer.jsx';
import {
  deleteJSON, loadingOverlay, parseForm, patchJSON, postJSON, updateByKey, removeByKey,
} from '../../duxfront/plugins/dux-utils';
import { globalDispatch, globalSelector } from '../../duxfront/plugins/dux-redux';
import { Translator } from '../../global/translator';
import { Authenticity } from '../../global/authenticity';
import { CustomFields } from '../custom_fields/CustomFields.jsx';

function CustomFormResponseInitialLoader({ customForm }) {
  const updateCustomForm = globalDispatch('customForm');
  const updateCustomFormResponses = globalDispatch('customFormResponses');

  React.useEffect(() => {
    updateCustomForm(customForm);
    updateCustomFormResponses(customForm.responses || []);
  });

  return null;
}

function CustomFormResponsesTable() {
  const translator = new Translator();
  const self = React.createRef();
  const customForm = globalSelector('customForm');
  const responses = globalSelector('customFormResponses');
  const updateSelectedCustomFormResponse = globalDispatch('selectedCustomFormResponse');

  const launchModal = React.useCallback((customFormResponse = {}) => {
    updateSelectedCustomFormResponse(customFormResponse);
  });

  return (
    <Container className="py-3">
      <Dashbox className="h-100">
        <DashboxTitle title={customForm?.title}>
          <Button rounded icon size="sm" variant="outline-primary" onClick={() => launchModal()}>
            <Icon name="plus" offset={1} />
          </Button>
        </DashboxTitle>
        <DashboxBody ref={self} table>
          { responses && responses.length > 0
            ? sortBy(responses, ['createdAt']).map((response) => (
              <tr key={response.code} className="cursor-pointer" onClick={() => launchModal(response)}>
                <td className="px-3">
                  <div className="font-weight-accent fs-12 text-secondary text-uppercase">
                    {response.formattedStatus}
                  </div>
                  <div className="font-weight-accent text-primary">
                    {response.formattedUserName}
                  </div>
                  <div className="fs-12 text-gray-500">
                    {response.formattedCreatedAt}
                  </div>
                </td>
              </tr>
            ))
            : (
              <tr>
                <td className="px-3">
                  <p>{translator.get('messages.no-results-found')}</p>
                </td>
              </tr>
            )}
        </DashboxBody>
      </Dashbox>
    </Container>
  );
}

function CustomFormResponseModal() {
  const translator = new Translator();
  const authenticityToken = new Authenticity().token();
  const self = React.createRef();
  const formId = 'custom_form_response_custom_fields';
  const selectedCustomFormResponse = globalSelector('selectedCustomFormResponse');
  const updateSelectedCustomFormResponse = globalDispatch('selectedCustomFormResponse');
  const customForm = globalSelector('customForm');
  const customFormResponses = globalSelector('customFormResponses');
  const updateCustomFormResponses = globalDispatch('customFormResponses');
  const customFields = selectedCustomFormResponse?.customFields || customForm?.customFields;
  const isUpdate = !!selectedCustomFormResponse?.code;
  const canEdit = !isUpdate;

  const closeModal = React.useCallback(() => {
    updateSelectedCustomFormResponse(null);
  });

  const submitCustomFormResponse = React.useCallback((e) => {
    e.preventDefault();

    loadingOverlay(self.current, 'show');

    // eslint-disable-next-line max-len
    const submissionUrl = selectedCustomFormResponse?.submissionUrl || customForm.customFormResponsesPath;
    const form = parseForm(e.target);
    const httpCall = isUpdate ? patchJSON : postJSON;

    httpCall(
      submissionUrl,
      form,
      (data) => {
        loadingOverlay(self.current, 'hide');
        updateCustomFormResponses(updateByKey(customFormResponses, data.object));
        closeModal();
      },
    );
  });

  const destroyCustomFormResponse = React.useCallback(() => {
    BootBox.confirm({
      size: 'small',
      message: translator.get('messages.are-you-sure'),
      callback: (success) => {
        if (!success) return;

        loadingOverlay(self.current, 'show');

        deleteJSON(
          selectedCustomFormResponse.submissionUrl,
          {},
          (data) => {
            loadingOverlay(self.current, 'hide');
            updateCustomFormResponses(removeByKey(customForm, data.object.key));
            closeModal();
          },
        );
      },
    });
  });

  return (
    <Modal centered show={!!selectedCustomFormResponse} onHide={closeModal}>
      <Modal.Header closeButton onHide={closeModal}>
        <Modal.Title>
          {canEdit ? translator.get('general.new') : translator.get('general.view')}
          { ' ' }
          {customForm?.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body ref={self} className="pt-2 px-3">
        <Form
          id={formId}
          onSubmit={submitCustomFormResponse}
          authenticityToken={authenticityToken}
          validate
          withBorders
        >
          <HiddenInput id="custom_form" formId={formId} defaultValue={customForm?.key} />
          <CustomFields formId={formId} fields={customFields} readOnly={!canEdit} />
          { (
            <>
              <Spacer block />
              <Col sm={6}>
                {
                  isUpdate
                    ? (
                      <Button size="sm" variant="danger" onClick={destroyCustomFormResponse} withIcon block>
                        <Icon name="trash" />
                        { translator.get('general.delete').toUpperCase() }
                      </Button>
                    )
                    : (
                      <Button size="sm" variant="gray-200" onClick={closeModal} withIcon block>
                        <Icon name="times" />
                        { translator.get('general.cancel').toUpperCase() }
                      </Button>
                    )
                }
              </Col>
              <Col sm={6}>
                <Button size="sm" variant="primary" type="submit" withIcon block>
                  <Icon name="check" />
                  { translator.get('general.save').toUpperCase() }
                </Button>
              </Col>
            </>
          )}
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export function CustomForm(props) {
  const {
    customForm,
  } = props;

  return (
    <GlobalWrapper {...props}>
      <CustomFormResponseInitialLoader customForm={customForm} />
      <CustomFormResponsesTable />
      <CustomFormResponseModal />
    </GlobalWrapper>
  );
}
