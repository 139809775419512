import { Dropdown } from 'react-bootstrap';
import * as React from 'react';
import moment from 'moment';
import _ from 'lodash';
import { Icon } from '../../../duxfront/duxstrap/components/Icon.jsx';
import { clipboardCopyElement } from '../../../duxfront/plugins/dux-utils';
import { Translator } from '../../../global/translator';
import { csvFromTable } from '../../../lib/file';
import { reportPlotSelector } from '../report-utils';

export function ReportTablePlotCopy({ boxContentRef }) {
  const translator = new Translator();
  const copyTable = React.useCallback(() => {
    clipboardCopyElement(boxContentRef.current);
  });

  return (
    <Dropdown.Item onClick={copyTable}>
      <Icon name="clipboard" className="text-primary mr-2" />
      { translator.get('general.copy') }
    </Dropdown.Item>
  );
}

export function ReportTablePlotCsv({ plotKey, boxContentRef }) {
  const translator = new Translator();
  const plot = reportPlotSelector(plotKey, ['title']);

  const exportToCSV = () => {
    const formattedDate = moment(new Date()).format('yyyyMMDDHHmmss');
    const formattedTitle = _.deburr(plot.title.replace(' ', '_').toLowerCase());
    const filename = `${formattedTitle}_${formattedDate}.csv`;
    csvFromTable(boxContentRef.current.querySelector('table'), filename);
  };

  return (
    <Dropdown.Item onClick={exportToCSV}>
      <Icon name="file-excel-o" className="text-primary mr-2" />
      { translator.get('titles.export-csv') }
    </Dropdown.Item>
  );
}
