import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import { reducer as formReducer } from 'redux-form';
import _ from 'lodash';

function baseGlobalSelector(globalReduxKey, attributes, comparison) {
  if (!attributes) {
    return useSelector(
      (state) => _.clone(state.global[globalReduxKey]),
      comparison,
    );
  }

  return useSelector(
    (state) => {
      const fullObject = state.global[globalReduxKey];
      return fullObject ? _.pick(fullObject, attributes) : fullObject;
    },
    comparison,
  );
}

export function globalSelector(globalReduxKey, attributes = null) {
  return baseGlobalSelector(globalReduxKey, attributes, _.isEqual);
}

export function globalMemoSelector(globalReduxKey, attributes = null) {
  return baseGlobalSelector(globalReduxKey, attributes, (a) => !!a);
}

export function globalDispatch(globalReduxKey) {
  const dispatch = useDispatch();

  return useCallback((data) => {
    dispatch({ type: globalReduxKey, payload: data });
  }, [dispatch, globalReduxKey]);
}

export function globalReducer(state = {}, action = null) {
  const stateChange = _.merge({}, state);
  stateChange[action.type] = action.payload;

  return stateChange;
}

export function globalStore() {
  return configureStore({
    reducer: {
      global: globalReducer,
      form: formReducer,
    },
  });
}
