import * as React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { GlobalWrapper } from '../../components/GlobalWrapper.jsx';
import { LocationBox } from '../../components/location/LocationBox.jsx';
import { Input } from '../../duxfront/duxstrap/components/Form.jsx';
import { Button } from '../../duxfront/duxstrap/components/Button.jsx';
import { Icon } from '../../duxfront/duxstrap/components/Icon.jsx';
import { Translator } from '../../global/translator';
import { NewLocationModal } from './components/NewLocationModal.jsx';
import { Dashbox, DashboxBody, DashboxTitle } from '../../duxfront/duxdash/components/Dashbox.jsx';
import { Project } from '../../global/project';

function mapObjectToArray(obj) {
  return Object.keys(obj).map((key) => ({ name: key, value: obj[key] }));
}

export function Locations(props) {
  const {
    locations,
    timezoneList,
    notificationLists,
    observationGroups,
  } = props;

  return (
    <GlobalWrapper {...props}>
      <LocationsView
        locations={locations}
        notificationLists={mapObjectToArray(notificationLists)}
        observationGroups={mapObjectToArray(observationGroups)}
        timezoneList={mapObjectToArray(timezoneList)}
      />
    </GlobalWrapper>
  );
}

function LocationsView({
  locations,
  timezoneList,
  notificationLists,
  observationGroups,
}) {
  const translator = new Translator();
  const project = new Project();
  const [showFilter, setShowFilter] = React.useState(false);
  const [filter, setFilter] = React.useState('');
  const [filteredLocations, setFilteredLocations] = React.useState([]);
  const [showModal, setShowModal] = React.useState(false);
  const canManageLocations = project.userCan('manage_locations');

  React.useEffect(() => {
    if (!locations) {
      return;
    }

    setFilteredLocations(
      locations.filter((location) => location.name.toLowerCase().includes(filter.toLowerCase())),
    );
  }, [locations, filter]);

  return (
    <Container fluid className="py-3">
      {filteredLocations && (
        <Row className="gutter-2">
          <Col xs={12}>
            <div className="d-flex align-items-center">
              <h1 className="h3 m-0 font-weight-accent">
                {`${translator.get('general.welcome-to')} ${project?.title}!`}
              </h1>
              <Button
                rounded
                icon
                size="sm"
                variant="outline-primary"
                className="ml-auto"
                onClick={() => setShowFilter(!showFilter)}
              >
                <Icon name="filter" />
              </Button>

              {canManageLocations && (
                <Button
                  rounded
                  icon
                  size="sm"
                  variant="outline-primary"
                  className="ml-2"
                  onClick={() => setShowModal(true)}
                >
                  <Icon name="plus" />
                </Button>
              )}
            </div>
          </Col>
          {showFilter && (
            <Col xs={12}>
              <Dashbox>
                <DashboxTitle>{translator.get('general.filters')}</DashboxTitle>
                <DashboxBody className="form-with-borders">
                  <Row>
                    <Col xs={12} md={4} lg={3} clas>
                      <Input label={translator.get('general.name')} onChange={(e) => setFilter(e.target.value)} />
                    </Col>
                  </Row>
                </DashboxBody>
              </Dashbox>
            </Col>
          )}
          {filteredLocations.map((location) => (
            <Col key={location.key} xs={12} sm={6} lg={4} xl={3}>
              <LocationBox location={location} />
            </Col>
          ))}
        </Row>
      )}
      <NewLocationModal
        show={showModal}
        setShow={setShowModal}
        project={project}
        timezoneList={timezoneList}
        notificationLists={notificationLists}
        observationGroups={observationGroups}
      />
    </Container>
  );
}
