import * as React from 'react';
import { Col, Modal } from 'react-bootstrap';
import { sortBy } from 'lodash';
import { GlobalWrapper } from '../GlobalWrapper.jsx';
import { Dashbox, DashboxBody, DashboxTitle } from '../../duxfront/duxdash/components/Dashbox.jsx';
import { Button } from '../../duxfront/duxstrap/components/Button.jsx';
import { Icon } from '../../duxfront/duxstrap/components/Icon.jsx';
import { Spacer } from '../../duxfront/duxstrap/components/Spacer.jsx';
import {
  Checkbox, Form, Input, Select,
} from '../../duxfront/duxstrap/components/Form.jsx';
import {
  deleteJSON, loadingOverlay, parseForm, patchJSON, postJSON, updateByKey, removeByKey,
} from '../../duxfront/plugins/dux-utils';
import { BootBox } from '../../duxfront/duxstrap/vendor/bootbox';
import { globalDispatch, globalSelector } from '../../duxfront/plugins/dux-redux';
import { Project } from '../../global/project';
import { Translator } from '../../global/translator';
import { Authenticity } from '../../global/authenticity';

function PermissionInitialLoader({ projectPermissions }) {
  const updateProjectPermissions = globalDispatch('projectPermissions');

  React.useEffect(() => updateProjectPermissions(projectPermissions));

  return null;
}

function PermissionsTable() {
  const translator = new Translator();
  const project = new Project();
  const self = React.createRef();
  const projectPermissions = globalSelector('projectPermissions');
  const updateSelectedProjectPermission = globalDispatch('selectedProjectPermission');
  const canEdit = project.userCan('manage_permissions');

  const launchModal = React.useCallback((projectPermission = {}) => {
    if (!canEdit) return;

    return updateSelectedProjectPermission(projectPermission);
  });

  return (
    <Dashbox className="h-100">
      <DashboxTitle title={translator.get('titles.permissions')}>
        { canEdit && (
          <Button rounded icon size="sm" variant="outline-primary" onClick={() => launchModal()}>
            <Icon name="plus" offset={1} />
          </Button>
        )}
      </DashboxTitle>
      <DashboxBody ref={self} table style={{ maxHeight: '300px' }}>
        { projectPermissions && projectPermissions.length > 0
          ? sortBy(projectPermissions, (p) => p.user.name).map((projectPermission) => (
            <tr key={projectPermission.id} className="cursor-pointer" onClick={() => launchModal(projectPermission)}>
              <td className="px-3">
                <div className="font-weight-accent">{projectPermission.user.name}</div>
                <div className="fs-12 text-gray-500">{projectPermission.user.email}</div>
                <div className="fs-12 text-gray-500">{projectPermission.role}</div>
              </td>
            </tr>
          ))
          : (
            <tr>
              <td className="px-3">
                <p>{translator.get('messages.no-results-found')}</p>
              </td>
            </tr>
          )}
      </DashboxBody>
    </Dashbox>
  );
}

function PermissionModal() {
  const translator = new Translator();
  const project = new Project();
  const authenticityToken = new Authenticity().token();
  const self = React.createRef();
  const formId = 'permission';
  const selectedProjectPermission = globalSelector('selectedProjectPermission');
  const updateSelectedProjectPermission = globalDispatch('selectedProjectPermission');
  const projectPermissions = globalSelector('projectPermissions');
  const updateProjectPermissions = globalDispatch('projectPermissions');
  const isUpdate = !!selectedProjectPermission?.id;
  const canEditPermission = project.userCan('manage_permissions');
  const [canAddUser, setCanAddUser] = React.useState(false);
  const canEditUser = canEditPermission && (
    canAddUser || (isUpdate && !selectedProjectPermission?.user?.active)
  );

  const closeModal = React.useCallback(() => {
    updateSelectedProjectPermission(null);
  });

  const submitProjectPermission = React.useCallback((e) => {
    e.preventDefault();

    loadingOverlay(self.current, 'show');

    const httpCall = isUpdate ? patchJSON : postJSON;
    const submissionUrl = selectedProjectPermission?.updatePath || project.permissionsPath;
    const form = parseForm(e.target);

    httpCall(
      submissionUrl,
      form,
      (data) => {
        loadingOverlay(self.current, 'hide');
        updateProjectPermissions(updateByKey(projectPermissions, data.object));
        closeModal();
      },
      (data) => {
        loadingOverlay(self.current, 'hide');
        closeModal();
        BootBox.alert(`Error: ${data.responseJSON.msg || 'An unknown error occurred'}`);
      },
    );
  });

  const destroyProjectPermission = React.useCallback(() => {
    BootBox.confirm({
      size: 'small',
      message: translator.get('messages.are-you-sure'),
      callback: (success) => {
        if (!success) return;

        loadingOverlay(self.current, 'show');

        deleteJSON(
          selectedProjectPermission.updatePath,
          {},
          (data) => {
            loadingOverlay(self.current, 'hide');
            updateProjectPermissions(removeByKey(projectPermissions, data.object.key));
            closeModal();
          },
          (data) => {
            loadingOverlay(self.current, 'hide');
            closeModal();
            BootBox.alert(`Error: ${data.responseJSON.msg || 'An unknown error occurred'}`);
          },
        );
      },
    });
  });

  return (
    <Modal centered show={!!selectedProjectPermission} onHide={closeModal}>
      <Modal.Header closeButton onHide={closeModal}>
        <Modal.Title>
          {isUpdate ? translator.get('general.update') : translator.get('general.add')}
          { ' ' }
          {translator.get('titles.permission')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body ref={self} className="pt-2 px-3">
        <Form
          id={formId}
          onSubmit={submitProjectPermission}
          authenticityToken={authenticityToken}
          validate
          withBorders
        >
          <Col sm={8}>
            <Input
              id="email"
              formId={formId}
              label={translator.get('general.email')}
              defaultValue={selectedProjectPermission?.user?.email}
              disabled={isUpdate}
              placeholder="user@email.com"
              validation="required"
            />
          </Col>
          <Col sm={4}>
            <Select
              id="role"
              formId={formId}
              label={translator.get('titles.permission')}
              defaultValue={selectedProjectPermission?.role}
              options={project.permissionRoles}
              disabled={!canEditPermission}
            />
          </Col>
          { canEditPermission && !isUpdate && (
            <Col sm={12}>
              <Checkbox
                id="new_user"
                formId={formId}
                label={translator.get('general.user_not_exist_create_new')}
                defaultChecked={false}
                onChange={(e) => setCanAddUser(e.target.checked)}
                block
              />
            </Col>
          ) }
          { (isUpdate || canAddUser) && (
            <>
              <Col sm={6}>
                <Input
                  id="name"
                  formId={formId}
                  label={translator.get('general.name')}
                  defaultValue={selectedProjectPermission?.user?.name}
                  disabled={!canEditUser}
                  placeholder="John Doe"
                  validation="required"
                />
              </Col>
              <Col sm={6}>
                <Input
                  id="phone"
                  formId={formId}
                  label={translator.get('general.phone')}
                  defaultValue={selectedProjectPermission?.user?.phone}
                  disabled={!canEditUser}
                  placeholder="+1 123 456 7890"
                />
              </Col>
              <Col sm={6}>
                <Select
                  id="language"
                  formId={formId}
                  label={translator.get('general.language')}
                  defaultValue={selectedProjectPermission?.user?.language}
                  disabled={!canEditUser}
                  options={project.languageOptions}
                />
              </Col>
              <Col sm={6}>
                <Select
                  id="timezone"
                  formId={formId}
                  label={translator.get('general.timezone')}
                  defaultValue={selectedProjectPermission?.user?.timezone}
                  disabled={!canEditUser}
                  options={project.timezoneOptions}
                />
              </Col>
            </>
          )}
          { canEditUser && (
            <Col sm={12}>
              <Checkbox
                id="send_invite"
                formId={formId}
                label={translator.get('general.send_invite_email')}
                defaultChecked={false}
                block
              />
            </Col>
          ) }
          { canEditPermission && (
            <>
              <Spacer block />
              <Col sm={6}>
                {
                  isUpdate
                    ? (
                      <Button size="sm" variant="danger" onClick={destroyProjectPermission} withIcon block>
                        <Icon name="trash" />
                        { translator.get('general.delete').toUpperCase() }
                      </Button>
                    )
                    : (
                      <Button size="sm" variant="gray-200" onClick={closeModal} withIcon block>
                        <Icon name="times" />
                        { translator.get('general.cancel').toUpperCase() }
                      </Button>
                    )
                }
              </Col>
              <Col sm={6}>
                <Button size="sm" variant="primary" type="submit" withIcon block>
                  <Icon name="check" />
                  { translator.get('general.save').toUpperCase() }
                </Button>
              </Col>
            </>
          )}
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export function Permissions(props) {
  const {
    projectPermissions,
  } = props;

  return (
    <GlobalWrapper {...props}>
      <PermissionInitialLoader projectPermissions={projectPermissions} />
      <PermissionsTable />
      <PermissionModal />
    </GlobalWrapper>
  );
}
