import moment from 'moment';
import { globalDispatch, globalMemoSelector, globalSelector } from '../../duxfront/plugins/dux-redux';
import { getJSON } from '../../duxfront/plugins/dux-utils';

export const WIDGET_TYPES = {
  INTERACTIVE: 'standard',
  INTERACTIVE_CUSTOM: 'interactive_custom',
  INTERACTIVE_PIE_CUSTOM: 'interactive_pie_custom',
  TABLE: 'table',
  TABLE_CUSTOM: 'table_custom',
  WIND_ROSE: 'wind_rose',
  TEXT_EDITOR: 'text_editor',
  MAP: 'map',
};

export const MODAL_TYPES = {
  ADVANCED: 'report-advanced-update',
  EDIT_CUSTOM_FIELDS: 'report-edit-custom-fields-update',
  APPLY_TEMPLATE: 'report-apply-template-update',
  PLOT_ADVANCED: 'report-plot-advanced-update',
  PLOT_GENERAL: 'report-plot-general-update',
  INTERACTIVE_PLOT_DATA: 'report-plot-interactive-data-update',
  INTERACTIVE_PLOT_AXIS: 'report-plot-interactive-axis-update',
  TABLE_PLOT_DATA: 'report-plot-table-data-update',
  GENERIC_PLOT_DATA: 'report-plot-generic-data-update',
  PROJECT_MAP_SELECTION: 'report-plot-project-map-selection',
};

export function parseDate(date) {
  if (!date) return null;

  return moment(date);
}

export function parseRangeDate(startDate, endDate) {
  const parsedRangeStartDate = !startDate || startDate === 'today'
    ? moment().startOf('day')
    : moment(startDate);

  const parsedRangeEndDate = !endDate || endDate === 'today'
    ? moment().subtract(1, 'day').endOf('day')
    : moment(endDate);

  return [parsedRangeStartDate, parsedRangeEndDate];
}

export function dumpDate(date, format = 'YYYY-MM-DD HH:mm:ss') {
  return date.format(format);
}

export function loadSources(report, plotOptions, setSources, updateError) {
  getJSON(
    plotOptions.locationListPath,
    { location_code: report.locationCode },
    (data) => {
      setSources(data.locations.map((source) => (
        {
          name: source.name,
          value: source.code,
          metrics: source.metrics.map((metric) => (
            {
              name: metric.title,
              value: metric.code,
            }
          )),
        }
      )));
    },
    () => updateError('Failed to load locations'),
  );
}

export function reportDispatch() {
  return globalDispatch('report');
}

export function reportSelector(attributes = null) {
  return globalSelector('report', attributes);
}

export function reportErrorDispatch() {
  return globalDispatch('report-error');
}

export function reportErrorSelector() {
  return globalSelector('report-error');
}

export function reportModalTypeDispatch() {
  return globalDispatch('report-modal-type');
}

export function reportModalTypeSelector() {
  return globalSelector('report-modal-type');
}

export function reportDateRangeDispatch() {
  return globalDispatch('report-date-range');
}

export function reportDateRangeSelector() {
  return globalSelector('report-date-range');
}

export function reportPlotOptionsDispatch() {
  return globalDispatch('report-plot-options');
}

export function reportPlotOptionsSelector() {
  return globalSelector('report-plot-options');
}

export function reportPlotKeysDispatch() {
  return globalDispatch('report-plot-keys');
}

export function reportPlotKeysSelector() {
  return globalSelector('report-plot-keys');
}

export function reportPlotDispatch(plotKey) {
  return globalDispatch(`report-plot:${plotKey}`);
}

export function reportPlotSelector(plotKey, attributes = null) {
  return globalSelector(`report-plot:${plotKey}`, attributes);
}

export function reportPlotMemo(plotKey, attributes = null) {
  return globalMemoSelector(`report-plot:${plotKey}`, attributes);
}

export function reportPlotSelectedKeyDispatch() {
  return globalDispatch('report-plot-selected-key');
}

export function reportPlotSelectedKeySelector() {
  return globalSelector('report-plot-selected-key');
}

export function reportPlotDataDispatch(plotKey) {
  return globalDispatch(`report-plot-data:${plotKey}`);
}

export function reportPlotDataSelector(plotKey) {
  return globalSelector(`report-plot-data:${plotKey}`);
}

export function reportPlotInformationDispatch(plotKey) {
  return globalDispatch(`report-plot-information:${plotKey}`);
}

export function reportPlotInformationSelector(plotKey) {
  return globalSelector(`report-plot-information:${plotKey}`);
}

export function reportPlotMapsDispatch(plotKey) {
  return globalDispatch(`report-plot-maps:${plotKey}`);
}

export function reportPlotMapsSelector(plotKey) {
  return globalSelector(`report-plot-maps:${plotKey}`);
}
