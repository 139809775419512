import * as React from 'react';
import { Col, Dropdown } from 'react-bootstrap';
import { Dashbox, DashboxBody } from '../../../duxfront/duxdash/components/Dashbox.jsx';
import { Icon } from '../../../duxfront/duxstrap/components/Icon.jsx';
import { Project } from '../../../global/project';
import { Translator } from '../../../global/translator';
import {
  ReportPlotBoxFooter,
  ReportPlotBoxFullScreenIcon,
  ReportPlotBoxHeader,
} from '../report_plot_base/ReportPlotBox.jsx';
import { ReportPlotDataLoader } from '../report_plot_base/ReportPlotDataLoader.jsx';
import { ReportPlotRefreshData } from '../report_plot_base/ReportPlotRefreshData.jsx';
import { ReportPlotClone } from '../report_plot_base/ReportPlotClone.jsx';
import { ReportPlotDelete } from '../report_plot_base/ReportPlotDelete.jsx';
import { ReportPlotAdvanced } from '../report_plot_base/ReportPlotAdvanced.jsx';
import {
  MODAL_TYPES,
  reportModalTypeDispatch,
  reportPlotSelectedKeyDispatch,
  reportPlotSelector,
} from '../report-utils';
import { parseForm } from '../../../duxfront/plugins/dux-utils';
import { TextEditor } from '../../../duxfront/duxdash/components/TextEditor.jsx';
import { Button } from '../../../duxfront/duxstrap/components/Button.jsx';
import { Form } from '../../../duxfront/duxstrap/components/Form.jsx';

function ReportTextEditorPlotBox({ plotKey, boxContentRef }) {
  const plot = reportPlotSelector(plotKey, ['key', 'order', 'columnSize']);
  const [fullScreen, setFullScreen] = React.useState(false);
  const [content, setContent] = React.useState('');
  const [edit, setEdit] = React.useState(false);

  const printClass = React.useCallback(() => {
    if (!content) { return 'd-print-none'; }

    return 'print-no-page-break';
  });

  return (
    <Col sm={plot.columnSize} className={printClass()} style={{ order: plot.order }}>
      <Dashbox fullViewPort={fullScreen}>
        <ReportTextEditorPlotHeader
          plotKey={plot.key}
          fullScreen={fullScreen}
          setFullScreen={setFullScreen}
          boxContentRef={boxContentRef}
          edit={edit}
          onEditChange={setEdit}
        />
        <ReportTextEditorPlotBody
          plotKey={plot.key}
          boxContentRef={boxContentRef}
          edit={edit}
          content={content}
          onEditChange={setEdit}
          onContentChange={setContent}
        />
        <ReportPlotBoxFooter plotKey={plot.key} />
      </Dashbox>
    </Col>
  );
}

function ReportTextEditorPlotHeader({
  plotKey, fullScreen, setFullScreen, edit, onEditChange,
}) {
  const plot = reportPlotSelector(plotKey, ['key', 'title']);
  const project = new Project();
  const translator = new Translator();
  const updateSelectedPlotKey = reportPlotSelectedKeyDispatch();
  const updateSelectedModalType = reportModalTypeDispatch();

  const launchModal = React.useCallback((modalType) => {
    updateSelectedPlotKey(plot.key);
    updateSelectedModalType(modalType);
  });

  return (
    <ReportPlotBoxHeader plotKey={plot.key} className="d-flex align-items-center">
      <Icon name="edit" className="cursor-pointer mr-2" onClick={() => onEditChange(!edit)} />
      <ReportPlotBoxFullScreenIcon fullScreen={fullScreen} setFullScreen={setFullScreen} />
      { project.userCan('edit_reports') && (
        <Dropdown as="div" className="d-inline-block cursor-pointer ml-2">
          <Dropdown.Toggle as="div" id="dropdown-basic">
            <Icon name="ellipsis-v" />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <ReportPlotRefreshData plotKey={plot.key} />
            <Dropdown.Item onClick={() => launchModal(MODAL_TYPES.PLOT_GENERAL)}>
              <Icon name="cogs" className="text-primary mr-2" />
              { translator.get('general.settings') }
            </Dropdown.Item>
            <ReportPlotClone plotKey={plot.key} />
            <ReportPlotDelete plotKey={plot.key} />
            <ReportPlotAdvanced plotKey={plot.key} />
          </Dropdown.Menu>
        </Dropdown>
      )}
    </ReportPlotBoxHeader>
  );
}

function ReportTextEditorPlotBody({
  plotKey, boxContentRef, onEditChange, edit, content, onContentChange,
}) {
  const plot = reportPlotSelector(plotKey);
  const translator = new Translator();
  const formId = 'text_editor_content';

  const onSubmit = React.useCallback((e) => {
    e.preventDefault();
    const formContent = parseForm(e.target);
    onContentChange(formContent[formId].text_editor);
    onEditChange(false);
  });

  return (
    <DashboxBody
      ref={boxContentRef}
      style={{ height: plot.height }}
    >
      { !edit && (
      // eslint-disable-next-line max-len
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
      <div className="d-flex flex-column" onClick={() => onEditChange(true)} style={{ cursor: 'text' }}>
        { !content && (<p className="my-2 mx-auto">{translator.get('messages.empty-comment-section')}</p>) }
        {content && (
        // eslint-disable-next-line react/no-danger
        <div className="py-3 w-100" dangerouslySetInnerHTML={{ __html: content }} />
        )}
      </div>
      )}

      {edit && (
      <Form
        id={formId}
        onSubmit={onSubmit}
        className="px-3 pb-3"
        validate
        withBorders
      >
        <Col sm={12}>
          <TextEditor id="text_editor" value={content} formId={formId} />
        </Col>

        <Col sm={12}>
          <div className="d-flex align-items-center justify-content-end">
            <Button
              size="sm"
              variant="gray-200"
              type="button"
              withIcon
              style={{ width: '140px' }}
              onClick={() => onEditChange(false)}
            >
              <Icon name="times" />
              { translator.get('general.cancel').toUpperCase() }
            </Button>

            <Button size="sm" type="submit" withIcon className="ml-2" style={{ width: '140px' }}>
              <Icon name="check" />
              { translator.get('general.save').toUpperCase() }
            </Button>
          </div>
        </Col>
      </Form>
      )}
    </DashboxBody>
  );
}

export function ReportTextEditorPlot({ plotKey }) {
  const plot = reportPlotSelector(plotKey, ['key']);
  const boxContentRef = React.useRef();

  return plot && (
    <>
      <ReportPlotDataLoader
        plotKey={plot.key}
        boxContentRef={boxContentRef}
      />
      <ReportTextEditorPlotBox
        plotKey={plot.key}
        boxContentRef={boxContentRef}
      />
    </>
  );
}
