import * as React from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import { ReportPlotModalWrapper } from '../report_plot_base/ReportPlotModalWrapper.jsx';
import { Translator } from '../../../global/translator';
import {
  MODAL_TYPES,
  reportModalTypeSelector,
  reportPlotMapsDispatch,
  reportPlotSelectedKeySelector,
  reportPlotSelector,
} from '../report-utils';
import { Input, Select } from '../../../duxfront/duxstrap/components/Form.jsx';
import { Button } from '../../../duxfront/duxstrap/components/Button.jsx';
import { Icon } from '../../../duxfront/duxstrap/components/Icon.jsx';
import { getJSON } from '../../../duxfront/plugins/dux-utils';
import { Project } from '../../../global/project';

export function ReportMapsModal() {
  const translator = new Translator();
  const reportPlotSelectedKey = reportPlotSelectedKeySelector();
  const plot = reportPlotSelector(reportPlotSelectedKey);
  const selectedModalType = reportModalTypeSelector();
  const updateMaps = reportPlotMapsDispatch(reportPlotSelectedKey);
  const show = plot && selectedModalType === MODAL_TYPES.PROJECT_MAP_SELECTION;
  const project = new Project();
  const [map, setMap] = React.useState({});
  const [selectedMaps, setSelectedMaps] = React.useState([]);
  const [maps, setMaps] = React.useState([]);
  const weightOptions = [1, 2, 3, 4, 5].map((w) => ({ value: w, name: w }));
  const defaultColor = '#000000';

  React.useEffect(() => {
    if (!show) return;

    getJSON(`/projects/${project.code}/maps`, {}, (data) => {
      setMaps(data.object);
    });
  }, [show]);

  React.useEffect(() => {
    if (!maps || maps.length === 0) {
      return;
    }

    setMap({
      ...map,
      id: maps[0].id,
      title: maps[0].title,
      content: maps[0].content,
      weight: weightOptions[0].value,
      color: defaultColor,
    });
  }, [maps]);

  const onSelectedMapChange = React.useCallback((e) => {
    // eslint-disable-next-line radix
    const selectedMap = maps.find((k) => k.id === parseInt(e.value));
    setMap({
      ...map, id: selectedMap.id, title: selectedMap.title, content: selectedMap.content,
    });
  });

  const addMapToList = React.useCallback(() => {
    if (!map.id) return;

    const index = selectedMaps.findIndex((item) => item.id === map.id);

    if (index !== -1) {
      const newList = [...selectedMaps];
      newList[index] = map;
      setSelectedMaps(newList);
      updateMaps(newList);
      return;
    }

    setSelectedMaps([...selectedMaps, map]);
    updateMaps([...selectedMaps, map]);
  });

  const removeMapFromList = React.useCallback((mapToRemove) => {
    setSelectedMaps(selectedMaps.filter((item) => item.id !== mapToRemove.id));
    updateMaps(selectedMaps.filter((item) => item.id !== mapToRemove.id));
  });

  return show && (
    <ReportPlotModalWrapper
      title={translator.get('titles.map-selection')}
      modalType={MODAL_TYPES.PROJECT_MAP_SELECTION}
    >
      <div className="px-3 pb-3">
        <Row>
          <Col sm={12} md={4}>
            <Select
              id="title"
              label={translator.get('general.title')}
              options={maps.map((k) => ({ value: k.id, name: k.title }))}
              onChange={onSelectedMapChange}
            />
          </Col>

          <Col sm={12} md>
            <Input
              id="color"
              type="color"
              defaultValue={defaultColor}
              onChange={(e) => setMap({ ...map, color: e.target.value })}
              label={translator.get('general.color')}
            />
          </Col>

          <Col sm={12} md>
            <Select
              id="weight"
              onChange={(e) => setMap({ ...map, weight: e.value })}
              options={weightOptions}
              label={translator.get('general.width')}
            />
          </Col>

          <Col sm={12} md={1} className="d-flex align-items-end justify-content-end mt-2 mt-md-0">
            <Button rounded icon size="sm" variant="outline-primary" onClick={addMapToList}>
              <Icon name="plus" offset={1} />
            </Button>
          </Col>
        </Row>
        {selectedMaps.length > 0 && (
        <Table size="sm" className="m-0 mt-3" hover>
          <tbody>
            {selectedMaps.map((k) => (
              <tr key={k.id}>
                <td>{k.title}</td>
                {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                <td>
                  <div style={{ backgroundColor: k.color, width: '20px', height: '20px' }} />
                </td>
                <td>{k.weight}</td>
                {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                <td className="p-0">
                  <div className="d-flex align-items-center justify-content-end w-100">
                    <Button rounded icon size="sm" variant="outline-danger" onClick={() => removeMapFromList(k)}>
                      <Icon name="minus" offset={1} />
                    </Button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        )}
      </div>
    </ReportPlotModalWrapper>
  );
}
