import { Dropdown } from 'react-bootstrap';
import * as React from 'react';
import { Icon } from '../../../duxfront/duxstrap/components/Icon.jsx';
import { Translator } from '../../../global/translator';
import { patchJSON } from '../../../duxfront/plugins/dux-utils';
import {
  reportPlotMemo,
  reportErrorDispatch,
} from '../report-utils';
import { ReportPlotNew } from './ReportPlotNew.jsx';

export function ReportPlotClone({ plotKey }) {
  const translator = new Translator();
  const plot = reportPlotMemo(plotKey, ['title', 'updatePath']);
  const updateError = reportErrorDispatch();
  const [newPlot, setNewPlot] = React.useState(null);

  const clonePlot = React.useCallback(() => {
    patchJSON(
      plot.updatePath,
      {
        clone_plot: {
          title: `[${translator.get('general.duplicate')}] ${plot.title}`,
        },
      },
      (result) => setNewPlot(result.object),
      () => {
        updateError({
          plotKey,
          message: 'Could not clone plot. Please try again later.',
        });
      },
    );
  });

  return (
    <Dropdown.Item onClick={clonePlot}>
      <Icon name="clone" className="text-primary mr-2" />
      { translator.get('general.duplicate') }
      { newPlot && (
        <ReportPlotNew newPlot={newPlot} setNewPlot={setNewPlot} />
      )}
    </Dropdown.Item>
  );
}
