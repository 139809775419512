import * as React from 'react';
import { Button as BsButton } from 'react-bootstrap';

export function Button(props) {
  const {
    children,
    rounded = false,
    icon = false,
    withIcon = false,
    block = false,
    className = '',
    ...forwarded
  } = props;

  const classes = [className];
  if (rounded) classes.push('btn-rounded');
  if (icon) classes.push('btn-icon');
  if (withIcon) classes.push('btn-with-icon');
  if (block) classes.push('px-md-5');

  return (
    <BsButton
      className={classes.join(' ').trim()}
      block={block}
      {...forwarded}
    >
      { children }
    </BsButton>
  );
}
