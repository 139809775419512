import * as React from 'react';
import {
  Row, Col, Container, Image,
} from 'react-bootstrap';
import moment from 'moment/moment';
import { BootBox } from '../../duxfront/duxstrap/vendor/bootbox';
import { GlobalWrapper } from '../GlobalWrapper.jsx';
import { Translator } from '../../global/translator';
import { Duxfront } from '../../global/duxfront';
import { Button } from '../../duxfront/duxstrap/components/Button.jsx';
import { Icon } from '../../duxfront/duxstrap/components/Icon.jsx';
import { DateRangePicker } from '../../duxfront/duxstrap/components/Form.jsx';
import { getUrlParameter } from '../../duxfront/plugins/dux-utils';
import { ReportInitialLoader } from './report_base/ReportInitialLoader.jsx';
import { ReportPlot } from './report_plot_base/ReportPlot.jsx';
import { ReportMenuDropdown } from './report_base/ReportMenuDropdown.jsx';
import { ReportAdvancedForm } from './report_base/ReportAdvancedForm.jsx';
import { ReportEditCustomFieldsForm } from './report_base/ReportEditCustomFieldsForm.jsx';
import { ReportApplyTemplateForm } from './report_base/ReportApplyTemplateForm.jsx';
import { ReportPlotGeneralForm } from './report_plot_base/ReportPlotGeneralForm.jsx';
import { ReportInteractivePlotDataForm } from './report_interactive_plot/ReportInteractivePlotDataForm.jsx';
import { ReportInteractivePlotAxisForm } from './report_interactive_plot/ReportInteractivePlotAxisForm.jsx';
import { ReportTablePlotDataForm } from './report_table_plot/ReportTablePlotDataForm.jsx';
import { ReportGenericPlotDataForm } from './report_plot_other/ReportGenericPlotDataForm.jsx';
import { ReportPlotAdvancedForm } from './report_plot_base/ReportPlotAdvancedForm.jsx';
import {
  dumpDate,
  reportDateRangeDispatch,
  reportErrorSelector,
  reportPlotSelector,
  reportSelector,
  reportPlotKeysSelector,
  reportDateRangeSelector, parseDate,
  parseRangeDate,
} from './report-utils';
import { ReportMapsModal } from './report_plot_map/ReportMapsModal.jsx';

function ReportTopBar() {
  const translator = new Translator();
  const duxfront = new Duxfront();
  const report = reportSelector(['title', 'locationName', 'isTemplate', 'isPrivate', 'previousPage', 'config']);
  const dateRange = reportDateRangeSelector();
  const updateReportDateRange = reportDateRangeDispatch();
  const [dateFilterFixed, setDateFilterFixed] = React.useState(false);
  const [dateRanges, setDateRanges] = React.useState(null);

  const updateDateUrlParams = React.useCallback((startDate, endDate) => {
    const params = new URLSearchParams(window.location.search);
    params.set('startDate', startDate);
    params.set('endDate', endDate);
    window.history.pushState({}, '', `?${params.toString()}`);
  });

  const onDateRangeChange = React.useCallback((newStartDate, newEndDate) => {
    const start = dumpDate(newStartDate);
    const end = dumpDate(newEndDate);

    updateDateUrlParams(start, end);
    updateReportDateRange({ startDate: start, endDate: end });
  });

  React.useEffect(() => {
    if (!report) return;

    const fixed = report.config?.dateFilter?.type === 'fixed';

    if (fixed && report.config.dateFilter?.value?.length > 0) {
      const ranges = {};
      report.config.dateFilter.value.forEach((range) => {
        ranges[range.title] = parseRangeDate(range.startDate, range.endDate);
      });

      const [start, end] = parseRangeDate(
        report.config.dateFilter.value[0].startDate,
        report.config.dateFilter.value[0].endDate,
      );
      setDateFilterFixed(fixed);
      setDateRanges(ranges);
      onDateRangeChange(start, end);
    } else if (!dateRange) {
      let startDate = getUrlParameter('startDate');
      let endDate = getUrlParameter('endDate');

      startDate = startDate ? moment(startDate, 'YYYY-MM-DD+HH:mm:ss') : moment().subtract(1, 'day');
      endDate = endDate ? moment(endDate, 'YYYY-MM-DD+HH:mm:ss') : moment();

      onDateRangeChange(startDate, endDate);
    }
  }, [report]);

  const generateReportType = React.useCallback(() => {
    if (!report) return null;
    if (report.isTemplate) return translator.get('general.report_template');

    if (report.locationName) {
      return `${translator.get('general.location_report')}: ${report.locationName}`;
    }

    if (report.isPrivate) return translator.get('general.private_report');

    return translator.get('general.public_report');
  });

  return report && (
    <>
      <Col sm={8}>
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <div className="fs-12 text-gray-600 text-uppercase d-print-none">
              { generateReportType() }
            </div>
            <h1 className="h3 m-0 font-weight-accent text-primary">
              {report.title}
            </h1>
            <div className="fs-12 text-gray-600 d-none d-print-block">
              {report.locationName && (
                <div>
                  {`${translator.get('titles.location')}: ${report.locationName}`}
                </div>
              )}
              {/* <div> */}
              {/*  {`${translator.get('general.generated_by')}: ${user.name}`} */}
              {/* </div> */}
              <div>
                {`${translator.get('general.generated_at')}: `}
                {moment().format(translator.get('formats.basic-date-time-js'))}
              </div>
              <div>
                {dateRange && (
                  <>
                    {`${translator.get('general.date_range')}: `}
                    {parseDate(dateRange.startDate).format(translator.get('formats.basic-date-time-js'))}
                    {' - '}
                    {parseDate(dateRange.endDate).format(translator.get('formats.basic-date-time-js'))}
                  </>
                )}
              </div>
            </div>
            <Button
              withIcon
              variant="outline-primary"
              size="xs"
              className="text-uppercase d-print-none"
              href={report.previousPage}
            >
              <Icon name="arrow-left" />
              {translator.get('general.back')}
            </Button>
            <ReportMenuDropdown />
          </div>
        </div>
      </Col>
      <Col sm={4} className="d-flex align-items-center justify-content-end">
        <div className="d-print-none">
          {dateRange && (
            <DateRangePicker
              width="300px"
              onChange={onDateRangeChange}
              startDate={parseDate(dateRange.startDate)}
              endDate={parseDate(dateRange.endDate)}
              fixedRange={dateFilterFixed}
              ranges={dateRanges}
            />
          )}
        </div>
        <Image
          src={duxfront.institutional.logo}
          className="d-none d-print-block"
          style={{ maxWidth: '200px', maxHeight: '120px' }}
        />
      </Col>
    </>
  );
}

function ReportError() {
  const error = reportErrorSelector();
  const plot = reportPlotSelector(error?.plotKey, ['title']);

  function message() {
    if (typeof error === 'string') return error;

    return `Error for <b> ${plot.title} </b>: <br><br> ${error.message}`;
  }

  React.useEffect(() => {
    if (!error) return;

    const alertMessage = message();

    BootBox.alert({
      message: alertMessage,
      size: alertMessage.length > 80 ? 'large' : 'small',
    });
  });

  return null;
}

function Report() {
  const plotKeys = reportPlotKeysSelector() || [];

  return (
    <Container fluid className="py-3 px-md-4" id="container-print">
      <Row className="gutter-2 mb-2">
        <ReportTopBar />
        <ReportEditCustomFieldsForm />
        { plotKeys.map((plotKey) => (
          <ReportPlot key={plotKey} plotKey={plotKey} />
        ))}
      </Row>
      <ReportError />
      <ReportAdvancedForm />
      <ReportApplyTemplateForm />
      <ReportPlotGeneralForm />
      <ReportInteractivePlotDataForm />
      <ReportInteractivePlotAxisForm />
      <ReportTablePlotDataForm />
      <ReportGenericPlotDataForm />
      <ReportPlotAdvancedForm />
      <ReportMapsModal />
    </Container>
  );
}

export function ReportView(props) {
  const {
    report,
    plotOptions,
  } = props;

  return (
    <GlobalWrapper {...props}>
      <ReportInitialLoader report={report} plotOptions={plotOptions} />
      <Report />
    </GlobalWrapper>
  );
}
