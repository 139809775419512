/* globals $ */

import * as React from 'react';
import { Form as BsForm } from 'react-bootstrap';
import ClassicEditor from '../vendor/ckeditor';

export function TextEditor(props) {
  const {
    id,
    formId,
    label,
    onLoad,
    validation = null,
    ...forwarded
  } = props;

  const self = React.createRef();
  const parsedId = formId ? `${formId}_${id}` : id;
  const parsedName = formId ? `${formId}[${id}]` : id;

  React.useEffect(() => {
    const obj = $(self.current);
    if (onLoad) onLoad(obj);
    if (validation) obj.duxValidate();

    obj.closest('.modal').removeAttr('tabindex');

    ClassicEditor.create(self.current, {
      mediaEmbed: {
        previewsInData: true,
      },
    });
  });

  return (
    <BsForm.Group controlId={parsedId}>
      <BsForm.Label>{label}</BsForm.Label>
      <BsForm.Control
        ref={self}
        name={parsedName}
        data-validate={validation}
        as="textarea"
        {...forwarded}
      />
    </BsForm.Group>
  );
}
