import { merge } from 'lodash';
import { globalObject } from './global_object';

export const Duxfront = function () {
  const self = this;

  this.set = (newValues) => {
    merge(self, globalObject.duxfront, newValues);
  };

  this.reload = () => {
    merge(self, globalObject.duxfront);
  };

  return this.reload();
};
