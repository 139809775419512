/* global window $ bootbox */

import {} from './copyright';
import Hammer from 'hammerjs';
import {} from 'expose-loader?exposes=moment!moment';
import {} from './vendor/daterangepicker';
import {} from './plugins/text-editor';
import {} from './plugins/json-editor';
import {} from './vendor/print.min';
import {} from './vendor/export';

export const Duxdash = {
  online: true,

  init() {
    Duxdash.Core();
  },

  check_online(msg) {
    const checkConnection = function () {
      if (!window.navigator.onLine) {
        Duxdash.online = false;
        bootbox.alert(msg, () => {
          $('.dashboard-window').loading('show');
          setTimeout(() => {
            $('.dashboard-window').loading('hide');
            checkConnection();
          }, 2000);
        });
      } else {
        Duxdash.online = true;
      }
    };
    setInterval(() => {
      if (Duxdash.online) {
        checkConnection();
      }
    }, 1500);
  },

  Core() {
    const sidebar = $('.sidebar')[0];
    const mobileSidebar = $('.sidebar-mobile')[0];

    if (sidebar && mobileSidebar) {
      Duxdash.sidebarCloseHammer = new Hammer.Manager(sidebar, {
        recognizers: [
          [Hammer.Swipe, {
            direction: Hammer.DIRECTION_DOWN,
          }],
        ],
      });

      Duxdash.sidebarOpenHammer = new Hammer.Manager(mobileSidebar, {
        recognizers: [
          [Hammer.Swipe, {
            direction: Hammer.DIRECTION_UP,
          }],
        ],
      });

      Duxdash.sidebarCloseHammer.on('swipe', () => {
        $('.sidebar-toggle, .sidebar-close')[0].click();
      });

      Duxdash.sidebarOpenHammer.on('swipe', () => {
        $('.sidebar-toggle')[0].click();
      });
    }

    $('.sidebar-toggle, .sidebar-close').click(() => {
      $('.sidebar, .sidebar-mobile').toggleClass('active');
    });

    $(window).on('beforeunload', () => {
      $('.sidebar, .sidebar-mobile').removeClass('active');
      $('.dashboard-window').loading('show', {
        zIndex: $('.sidebar').css('z-index') - 1,
      });
    });

    $('.dashboard-content').click(() => {
      $('.sidebar, .sidebar-mobile').removeClass('active');
    });

    $('.nav-tree > .nav-link').click(function (e) {
      const navtree = $(this).closest('.nav-tree');
      e.preventDefault();
      navtree.toggleClass('uncollapsed');
      navtree.find('> .nav').collapse('toggle');
    });

    $('.dashbox-collapse').click(function (e) {
      const dashbox = $(this).closest('.dashbox');
      e.preventDefault();
      dashbox.find('.dashbox-collapse-icon').toggleClass('uncollapsed');
      dashbox.find('.dashbox-body').collapse('toggle');
    });

    $('.dashbox-close').click(function (e) {
      e.preventDefault();
      $(this).closest('.dashbox').remove();
    });
  },
};
