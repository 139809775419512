import * as React from 'react';
import _ from 'lodash';
import { Toast } from 'react-bootstrap';
import { BootBox } from '../../duxfront/duxstrap/vendor/bootbox';
import { GlobalWrapper } from '../GlobalWrapper.jsx';
import { globalDispatch, globalSelector } from '../../duxfront/plugins/dux-redux';
import { LocationBox } from './LocationBox.jsx';
import {
  getJSON, removeByKey, updateByKey,
} from '../../duxfront/plugins/dux-utils';
import { Project } from '../../global/project';
import LocationsMap from '../maps/LocationsMap.jsx';

function LocationLoader({ locations }) {
  const project = new Project();
  const currentLocations = globalSelector('locations');
  const updateLocations = globalDispatch('locations');
  const currentScreenNotifications = globalSelector('screen_notifications') || [];
  const updateScreenNotifications = globalDispatch('screen_notifications');
  const autoUpdateSeconds = project.config.autoUpdateLocations;

  React.useEffect(() => {
    if (!currentLocations) {
      updateLocations(locations);

      if (autoUpdateSeconds) BootBox.alert('Atualizações automáticas estão ativadas.');
    }

    if (!autoUpdateSeconds) return;

    const intervalId = setInterval(() => {
      getJSON(
        project.locationUpdatesPath,
        { seconds_ago: autoUpdateSeconds },
        (data) => {
          if (data.locations.length > 0) {
            updateLocations(updateByKey(currentLocations, data.locations, 'code'));
          }

          if (data.screenNotifications.length > 0) {
            updateScreenNotifications(updateByKey(currentScreenNotifications, data.screenNotifications));
          }
        },
      );
    }, autoUpdateSeconds * 1000);

    return () => clearInterval(intervalId);
  });

  return null;
}

function NotificationBar() {
  const screenNotifications = globalSelector('screen_notifications') || [];
  const updateScreenNotifications = globalDispatch('screen_notifications');

  const dismissNotification = React.useCallback((key) => {
    updateScreenNotifications(removeByKey(screenNotifications, key));
  });

  React.useEffect(() => {
    screenNotifications.forEach((notification) => {
      if (notification.sound) {
        // eslint-disable-next-line no-undef
        new Audio(notification.sound).play();
      }
    });
  });

  return (
    <div className="fix-content-corner-top mt-2 mr-2">
      { screenNotifications.map((notification) => (
        <Toast show onClose={() => dismissNotification(notification.key)} key={notification.key}>
          <Toast.Header>
            <strong className="mr-auto">{notification.title}</strong>
            <small>{notification.time}</small>
          </Toast.Header>
          <Toast.Body>{notification.message}</Toast.Body>
        </Toast>
      ))}
    </div>
  );
}

function LocationWindow({ location }) {
  return (
    <div style={{ maxWidth: '400px' }}>
      <LocationBox location={_.cloneDeep(location)} />
    </div>
  );
}

function LocationsMapWrapper() {
  const locations = globalSelector('locations');

  return (
    <>
      <NotificationBar />
      <LocationsMap
        className="vh-100"
        locations={locations}
        locationWindow={LocationWindow}
      />
    </>
  );
}

export function LocationMap(props) {
  const { locations } = props;

  return (
    <GlobalWrapper {...props}>
      <LocationLoader locations={locations} />
      <LocationsMapWrapper />
    </GlobalWrapper>
  );
}
