import * as React from 'react';
import { Col } from 'react-bootstrap';
import { Form } from '../../../duxfront/duxstrap/components/Form.jsx';
import { Spacer } from '../../../duxfront/duxstrap/components/Spacer.jsx';
import { Button } from '../../../duxfront/duxstrap/components/Button.jsx';
import { Icon } from '../../../duxfront/duxstrap/components/Icon.jsx';
import { Authenticity } from '../../../global/authenticity';
import { Translator } from '../../../global/translator';
import {
  reportErrorDispatch,
  reportModalTypeDispatch,
} from '../report-utils';
import { loadingOverlay, parseForm, patchJSON } from '../../../duxfront/plugins/dux-utils';

export function ReportFormWrapper({
  formId,
  children,
  self = React.createRef(),
  onClose = null,
  onSave = null,
  onNext = null,
  onRemove = null,
  prepareData = null,
  afterSave = null,
  updatePath = null,
  modal = true,
  compact = false,
  readOnly = false,
  hidden = false,
}) {
  const translator = new Translator();
  const authenticityToken = new Authenticity().token();
  const updateReportModalType = reportModalTypeDispatch();
  const updateError = reportErrorDispatch();
  const classes = ['pb-3', 'px-3'];

  if (hidden) classes.push('d-none');

  const closeModal = onClose || React.useCallback(() => {
    updateReportModalType(null);
  });

  const prepareFormData = prepareData || React.useCallback((parsedForm) => parsedForm);

  const submitForm = onNext || onSave || React.useCallback((e) => {
    e.preventDefault();
    loadingOverlay(self.current, 'show');

    patchJSON(
      updatePath,
      prepareFormData(parseForm(e.target)),
      (data) => {
        loadingOverlay(self.current, 'hide');
        (afterSave || closeModal)(data);
      },
      () => {
        loadingOverlay(self.current, 'hide');
        updateError('Failed to save changes.');
      },
    );
  });

  return (
    <Form
      id={formId}
      onSubmit={submitForm}
      authenticityToken={authenticityToken}
      ref={self}
      className={classes.join(' ')}
      validate
      withBorders
    >
      { children }
      { !readOnly && (
        <>
          <Spacer size={2} block />
          <Col sm={6} md={compact || modal ? 6 : 9}>
            { onRemove ? (
              <Button size="sm" variant="outline-danger" onClick={onRemove} className="text-uppercase" withIcon block>
                <Icon name="trash" />
                { translator.get('general.remove') }
              </Button>
            ) : (modal && (
              <Button size="sm" variant="gray-200" onClick={closeModal} className="text-uppercase" withIcon block>
                <Icon name="times" />
                { translator.get('general.cancel') }
              </Button>
            ))}
          </Col>
          <Col sm={6} md={compact || modal ? 6 : 3}>
            <Button size="sm" variant="primary" type="submit" className="text-uppercase" withIcon block>
              <Icon name="check" />
              { onNext ? translator.get('general.next') : translator.get('general.save') }
            </Button>
          </Col>
        </>
      )}
    </Form>
  );
}
