/* globals $ */

import { globalObject } from './global_object';
import {
  Translator as DuxTranslator,
} from '../duxfront/plugins/dux-utils';

export const Translator = () => {
  if (globalObject.translator) return globalObject.translator;

  const getTranslations = () => {
    let translations = globalObject?.translations;

    if (!translations && global.window) {
      translations = JSON.parse($('meta[name="translator"]').attr('content').replace(/\\"/g, '"'));
    }

    return translations;
  };

  globalObject.translator = new DuxTranslator(
    getTranslations,
    globalObject.duxfront?.layout?.language,
  );

  return globalObject.translator;
};
