import * as React from 'react';

export function Dashbox(props) {
  const {
    children,
    className = '',
    fullViewPort = false,
    ...forwarded
  } = props;

  const classes = ['dashbox', className];

  if (fullViewPort) classes.push('full-viewport');

  return (
    <div className={classes.join(' ')} {...forwarded}>
      { children }
    </div>
  );
}

export function DashboxTitle(props) {
  const {
    children,
    title,
  } = props;

  const defaultTitle = function () {
    return (
      <>
        <div className="h4 m-0">{ title }</div>
        <div className="d-flex align-items-center">
          { children}
        </div>
      </>
    );
  };

  return (
    <div className="dashbox-title">
      { title ? defaultTitle() : children}
    </div>
  );
}

export const DashboxBody = React.forwardRef((props, ref) => {
  const {
    children,
    table = false,
    className = '',
    ...forwarded
  } = props;

  if (table) {
    return (
      <div ref={ref} className={`dashbox-body p-0 ${className}`} {...forwarded}>
        <table className="table table-sm table-break-word table-hover m-0 border-bottom">
          <tbody>{children}</tbody>
        </table>
      </div>
    );
  }

  return (
    <div ref={ref} className={`dashbox-body ${className}`} {...forwarded}>
      { children }
    </div>
  );
});

export function DashboxFooter(props) {
  const {
    children,
    className = '',
  } = props;

  return (
    <div className={`dashbox-footer ${className}`}>
      { children }
    </div>
  );
}
