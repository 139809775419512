import * as React from 'react';
import { TableField } from './TableField.jsx';
import { CoreFields } from './CoreFields.jsx';

export function CustomField({ formId, fieldDefinition, readOnly = false }) {
  switch (fieldDefinition.type) {
    case 'table':
      return <TableField formId={formId} fieldDefinition={fieldDefinition} readOnly={readOnly} />;
    default:
      return <CoreFields formId={formId} fieldDefinition={fieldDefinition} readOnly={readOnly} />;
  }
}
