import { Col } from 'react-bootstrap';
import * as React from 'react';
import { CustomField } from './CustomField.jsx';

export function CustomFields({ formId, fields, readOnly = false }) {
  return (
    <>
      {fields.map((fieldDefinition) => (
        <Col key={fieldDefinition.id} sm={fieldDefinition.columnSize}>
          <CustomField
            formId={formId}
            fieldDefinition={fieldDefinition}
            readOnly={readOnly}
          />
        </Col>
      ))}
    </>
  );
}
