import { merge } from 'lodash';
import { globalObject } from './global_object';

export const User = function () {
  const self = this;

  this.set = (newValues) => {
    merge(self, globalObject.user, newValues);
  };

  this.reload = () => {
    merge(self, globalObject.user);
  };

  return this.reload();
};
