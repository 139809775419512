import * as React from 'react';
import { Col } from 'react-bootstrap';
import { JSONEditor } from '../../../duxfront/duxdash/components/JSONEditor.jsx';
import { Translator } from '../../../global/translator';
import { ReportModalWrapper } from './ReportModalWrapper.jsx';
import { ReportFormWrapper } from './ReportFormWrapper.jsx';
import {
  MODAL_TYPES,
  reportDispatch,
  reportModalTypeDispatch,
  reportSelector,
} from '../report-utils';

export function ReportAdvancedForm() {
  const translator = new Translator();
  const report = reportSelector();
  const updateReport = reportDispatch();
  const updateReportModalType = reportModalTypeDispatch();
  const formId = 'report_advanced';
  const title = translator.get('general.advanced');

  const onResponse = React.useCallback((data) => {
    updateReport(data.object);
    updateReportModalType(null);
  });

  return report && (
    <ReportModalWrapper
      title={title}
      modalType={MODAL_TYPES.ADVANCED}
    >
      <ReportFormWrapper
        formId={formId}
        afterSave={onResponse}
        updatePath={report.updatePath}
      >
        <Col>
          <JSONEditor
            collapsed
            formId={formId}
            inputId="config"
            json={report.config}
            style={{ maxHeight: '40vh' }}
            forceSnakeCase
          />
        </Col>
      </ReportFormWrapper>
    </ReportModalWrapper>
  );
}
