import * as React from 'react';
import { Col, FormLabel, Row } from 'react-bootstrap';
import { camelCase } from 'lodash';
import { Translator } from '../../global/translator';
import { Button } from '../../duxfront/duxstrap/components/Button.jsx';
import { Icon } from '../../duxfront/duxstrap/components/Icon.jsx';
import { CoreFields } from './CoreFields.jsx';

const TableFieldRow = React.memo(({
  formId, tableFieldDefinition, rowIndex, rowValues, removeRow, readOnly = false,
}) => (
  <Col xs={12} key={rowIndex}>
    <div className="bordered p-2">
      <Row className="gutter-1">
        <Col>
          <Row className="gutter-1">
            {tableFieldDefinition.items.map((fieldDefinition, columIndex) => (
              <TableFieldItem
                // eslint-disable-next-line react/no-array-index-key
                key={columIndex}
                formId={formId}
                parentId={tableFieldDefinition.id}
                fieldDefinition={fieldDefinition}
                readOnly={readOnly}
                rowIndex={rowIndex}
                columIndex={columIndex}
                value={rowValues[camelCase(fieldDefinition.id)]}
              />
            ))}
          </Row>
        </Col>
        <Col className="text-right" xs="auto">
          <Icon
            name="times"
            onClick={() => removeRow(rowIndex)}
            className="mb-2 cursor-pointer label-margin fs-14"
            style={{ paddingTop: '17px' }}
          />
        </Col>
      </Row>
    </div>
  </Col>
));

function TableFieldItem({
  formId, parentId, fieldDefinition, rowIndex, columIndex, value, readOnly = false,
}) {
  const parsedDefinition = {
    ...fieldDefinition,
    ...{
      id: `${parentId}:${rowIndex}:${fieldDefinition.id}`,
      value: value || '',
    },
  };

  return (
    <Col key={columIndex} sm={fieldDefinition.columnSize}>
      <CoreFields formId={formId} fieldDefinition={parsedDefinition} readOnly={readOnly} />
    </Col>
  );
}

export function TableField({ formId, fieldDefinition, readOnly = false }) {
  const translator = new Translator();
  const [tableFieldValues, setTableFieldValues] = React.useState(fieldDefinition.value || []);

  const addRow = React.useCallback(() => {
    setTableFieldValues([
      ...tableFieldValues,
      {},
    ]);
  });

  const removeRow = React.useCallback((rowIndex) => {
    setTableFieldValues(tableFieldValues.filter((_, i) => i !== rowIndex));
  });

  return (
    <>
      <FormLabel>{ fieldDefinition.label }</FormLabel>
      <div className="bordered p-2">
        <Row className="gutter-1">
          {tableFieldValues.map((rowValues, rowIndex) => (
            <TableFieldRow
              // eslint-disable-next-line react/no-array-index-key
              key={rowIndex}
              formId={formId}
              tableFieldDefinition={fieldDefinition}
              rowIndex={rowIndex}
              rowValues={rowValues}
              removeRow={removeRow}
              readOnly={readOnly}
            />
          ))}
          <Col xs={12} className="text-center">
            <Button onClick={addRow} size="xs" className="text-uppercase" withIcon>
              <Icon name="plus" />
              {translator.get('general.add')}
            </Button>
          </Col>
        </Row>
      </div>
    </>
  );
}
