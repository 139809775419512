import * as React from 'react';
import { ReportModalWrapper } from '../report_base/ReportModalWrapper.jsx';
import {
  reportModalTypeDispatch,
  reportPlotSelectedKeyDispatch,
  reportPlotSelectedKeySelector,
  reportPlotSelector,
} from '../report-utils';

export function ReportPlotModalWrapper({
  modalType, title, children, onClose = null,
}) {
  const reportPlotSelectedKey = reportPlotSelectedKeySelector();
  const plot = reportPlotSelector(reportPlotSelectedKey);
  const updateReportModalType = reportModalTypeDispatch();
  const updateReportPlotSelectedKey = reportPlotSelectedKeyDispatch();

  const closeModal = onClose || React.useCallback(() => {
    updateReportModalType(null);
    updateReportPlotSelectedKey(null);
  });

  return (
    <ReportModalWrapper
      modalType={modalType}
      title={title}
      show={!!plot}
      onClose={closeModal}
    >
      { children }
    </ReportModalWrapper>
  );
}
