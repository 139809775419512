import * as React from 'react';
import { sortBy } from 'lodash';
import { Container } from 'react-bootstrap';
import { GlobalWrapper } from '../GlobalWrapper.jsx';
import { Dashbox, DashboxBody, DashboxTitle } from '../../duxfront/duxdash/components/Dashbox.jsx';
import { globalDispatch, globalSelector } from '../../duxfront/plugins/dux-redux';
import { Translator } from '../../global/translator';

function CustomFormInitialLoader({ customForms }) {
  const updateCustomForms = globalDispatch('customForms');

  React.useEffect(() => updateCustomForms(customForms));

  return null;
}

function CustomFormsTable() {
  const translator = new Translator();
  const self = React.createRef();
  const customForms = globalSelector('customForms');

  return (
    <Container className="py-3">
      <Dashbox className="h-100">
        <DashboxTitle title={translator.get('general.forms')} />
        <DashboxBody ref={self} table>
          { customForms && customForms.length > 0
            ? sortBy(customForms, ['title']).map((form) => (
              <tr key={form.code} className="cursor-pointer link-wrapper">
                <td className="px-3">
                  <a href={form.viewPath}>
                    <div className="font-weight-accent">{form.title}</div>
                    <div className="fs-12 text-gray-500">{form.description}</div>
                  </a>
                </td>
              </tr>
            ))
            : (
              <tr>
                <td className="px-3">
                  <p>{translator.get('messages.no-results-found')}</p>
                </td>
              </tr>
            )}
        </DashboxBody>
      </Dashbox>
    </Container>
  );
}

export function CustomFormsIndex(props) {
  const {
    customForms,
  } = props;

  return (
    <GlobalWrapper {...props}>
      <CustomFormInitialLoader customForms={customForms} />
      <CustomFormsTable />
    </GlobalWrapper>
  );
}
