import * as React from 'react';
import {
  Alert,
  Col,
  Dropdown,
  Modal,
  OverlayTrigger,
  Row,
  Tooltip,
} from 'react-bootstrap';
import {
  Dashbox,
  DashboxBody,
  DashboxFooter,
  DashboxTitle,
} from '../../duxfront/duxdash/components/Dashbox.jsx';
import { Button } from '../../duxfront/duxstrap/components/Button.jsx';
import { Icon } from '../../duxfront/duxstrap/components/Icon.jsx';
import { Spacer } from '../../duxfront/duxstrap/components/Spacer.jsx';
import { LocationLink } from '../../duxfront/duxstrap/components/LocationLink.jsx';
import { Form, HiddenInput, Input } from '../../duxfront/duxstrap/components/Form.jsx';
import { Translator } from '../../global/translator';
import { Authenticity } from '../../global/authenticity';

function LocationParsers({ location }) {
  const translator = new Translator();

  return location.parsers.map((parser) => (
    <Col xs={6} key={parser.key}>
      <div className="fs-12 text-uppercase font-weight-accent">{parser.shortTitle}</div>
      <div className="fs-10 font-weight-accent text-uppercase">{parser.sourceTypeFormatted}</div>
      <div className="fs-10 text-uppercase">
        {`${translator.get('general.last-data')}: `}
        <span className="font-weight-bold text-secondary">{parser.lastLogFormatted}</span>
      </div>
      <div className="fs-10 text-uppercase">
        {`${translator.get('general.last-connection')}: `}
        <span className="font-weight-bold text-secondary">{parser.lastConnectionFormatted}</span>
      </div>
    </Col>
  ));
}

function LocationMetrics({ location }) {
  return location.metrics.map((metric) => (
    <Col md={6} key={metric.key} className="fs-12">
      { metric.alertTriggered ? (
        <div className="text-danger">
          {`${metric.name}: `}
          <span className="font-weight-bold text-danger">{metric.latestValueFormatted}</span>
          <OverlayTrigger
            placement="bottom"
            overlay={(
              <Tooltip>
                <div>{metric.alertCondition}</div>
                <div>{metric.alertStatusFormatted}</div>
                <div>{metric.latestAlertTimeFormatted}</div>
              </Tooltip>
            )}
          >
            <Icon name="exclamation-triangle" className="ml-1" />
          </OverlayTrigger>
        </div>
      ) : (
        <div>
          {`${metric.name}: `}
          <span className="font-weight-bold text-primary">{metric.latestValueFormatted}</span>
        </div>
      )}
    </Col>
  ));
}

function LocationObservations({ location }) {
  const translator = new Translator();

  location.openObservations.forEach((observation) => {
    if (observation.isWarning) {
      // eslint-disable-next-line no-param-reassign
      observation.severityBannerVariant = 'orange';
    } else if (observation.isCritical) {
      // eslint-disable-next-line no-param-reassign
      observation.severityBannerVariant = 'danger';
    } else {
      // eslint-disable-next-line no-param-reassign
      observation.severityBannerVariant = 'warning';
    }
  });

  return location.openObservations.length === 0 ? (
    <Row className="align-items-center justify-content-center h-100">
      <Col xs="auto" className="text-uppercase text-center">
        <Icon name="check" className="text-secondary fs-18" />
        <div>
          { translator.get('general.no_observations') }
        </div>
      </Col>
    </Row>
  ) : (
    <Row className="gutter-1">
      { location.openObservations.map((observation) => (
        <Col md={12} key={observation.key}>
          <Alert variant={observation.severityBannerVariant}>
            <div className="font-weight-bold fs-12">{observation.observationGroup.name}</div>
            <div className="font-weight-bold fs-14">
              {`[${observation.formattedSeverity.toUpperCase()}] - ${observation.reportedReason}`}
            </div>
            <div className="fs-12">
              <div>{`${observation.observationType} (${observation.activityType})`}</div>
              {/* <div>{observation.formattedReportedBy}</div> */}
              <div>{observation.formattedReportedAt}</div>
              {observation.latitude && observation.longitude && (
                <div>
                  <LocationLink
                    latitude={observation.latitude}
                    longitude={observation.longitude}
                    className="text-white text-underlined"
                  >
                    {translator.get('general.view_location')}
                  </LocationLink>
                </div>
              )}
            </div>
          </Alert>
        </Col>
      ))}
    </Row>
  );
}

function LocationAddReportModal({ location, show, setShow }) {
  const translator = new Translator();
  const authenticityToken = new Authenticity().token();
  const self = React.useRef();
  const formId = 'report';

  const closeModal = React.useCallback(() => setShow(false));

  return (
    <Modal centered show={show} onHide={closeModal}>
      <Modal.Header closeButton onHide={closeModal}>
        <Modal.Title>
          {`${translator.get('general.add')} ${translator.get('general.location_report')}`}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form
          id={formId}
          action={location.addReportPath}
          method="post"
          authenticityToken={authenticityToken}
          ref={self}
          validate
          withBorders
        >
          <HiddenInput
            id="location"
            formId={formId}
            value={location.code}
          />
          <Col sm={12}>
            <Input
              id="title"
              formId={formId}
              label={translator.get('general.title')}
              placeholder="New Report"
              validation="required"
            />
          </Col>
          <Spacer size={2} block />
          <Col sm={6}>
            <Button size="sm" variant="gray-200" onClick={closeModal} className="text-uppercase" withIcon block>
              <Icon name="times" />
              { translator.get('general.cancel') }
            </Button>
          </Col>
          <Col sm={6}>
            <Button size="sm" variant="primary" type="submit" className="text-uppercase" withIcon block>
              <Icon name="check" />
              { translator.get('general.save') }
            </Button>
          </Col>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export function LocationDataVisualizationButton({ location }) {
  const translator = new Translator();
  const [showAddModal, setShowAddModal] = React.useState(false);

  return (
    <Dropdown as="div" className="d-inline-block cursor-pointer">
      <Dropdown.Toggle as="div" id="dropdown-basic">
        <Button withIcon size="xs" variant="secondary" className="text-uppercase">
          <Icon name="area-chart" />
          { translator.get('general.data-visualization') }
        </Button>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        { location.reports.map((report) => (
          <Dropdown.Item key={report.code} href={report.viewPath} target="_blank">
            <Icon name="area-chart" className="text-primary mr-2" />
            { report.title }
          </Dropdown.Item>
        ))}
        <Dropdown.Item onClick={() => setShowAddModal(true)}>
          <Icon name="plus" className="text-primary mr-2" />
          { `${translator.get('general.add')} ${translator.get('titles.report')}` }
        </Dropdown.Item>
      </Dropdown.Menu>
      { showAddModal && (
        <LocationAddReportModal
          location={location}
          show={showAddModal}
          setShow={setShowAddModal}
        />
      )}
    </Dropdown>
  );
}

export function LocationBox({ location }) {
  const translator = new Translator();

  return (
    <Dashbox className="h-100" style={{ minWidth: '360px' }}>
      <DashboxTitle>
        <div className="fs-18 font-weight-accent text-primary">
          {location.name}
          <div className="fs-12 text-muted">{location.code}</div>
        </div>
        {/*{ location.alertTriggered && (*/}
        {/*  <Icon name="exclamation-triangle" className="text-danger ml-1" />*/}
        {/*)}*/}
      </DashboxTitle>
      <DashboxBody>
        { location.isObservation ? (
          <LocationObservations location={location} />
        ) : (
          <>
            <Row className="gutter-1 mb-2">
              <LocationParsers location={location} />
            </Row>
            <Row className="gutter-1">
              <LocationMetrics location={location} />
            </Row>
          </>
        )}
      </DashboxBody>
      <DashboxFooter>
        <Button
          href={location.detailsPath}
          target="_blank"
          withIcon
          size="xs"
          variant="primary"
          className="text-uppercase mr-2"
        >
          <Icon name="list-ul" offset={1} />
          { translator.get('general.details') }
        </Button>
        { location.isObservation ? (
          <Button
            href={location.observationsPath}
            target="_blank"
            withIcon
            size="xs"
            variant="secondary"
            className="text-uppercase"
          >
            <Icon name="bell" />
            { translator.get('general.observations') }
          </Button>
        ) : (
          <LocationDataVisualizationButton location={location} />
        )}
      </DashboxFooter>
    </Dashbox>
  );
}
