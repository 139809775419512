import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { User } from '../../global/user';
import { Translator } from '../../global/translator';
import { GlobalWrapper } from '../../components/GlobalWrapper.jsx';
import { Dashbox, DashboxBody, DashboxTitle } from '../../duxfront/duxdash/components/Dashbox.jsx';
import { NewProjectModal } from './components/NewProjectModal.jsx';
import { Icon } from '../../duxfront/duxstrap/components/Icon.jsx';
import { Button } from '../../duxfront/duxstrap/components/Button.jsx';

export function DashboardContent({ projectPermissions }) {
  const user = new User();
  const translator = new Translator();
  const [showModal, setShowModal] = React.useState(false);

  return (
    <Container className="py-5">
      <Row className="gutter-2">
        <Col sm={12}>
          <h4 className="font-weight-accent m-0">
            {translator.get('general.welcome')}
            {', '}
            {user.name}
          </h4>
        </Col>
        <Col sm={12}>
          <Dashbox>
            <DashboxTitle title={translator.get('titles.my-projects')}>
              {user.canAddProjects && (
                <Button rounded icon size="sm" variant="outline-primary" onClick={() => setShowModal(true)}>
                  <Icon name="plus" offset={1} />
                </Button>
              )}
            </DashboxTitle>
            <DashboxBody table>
              {!projectPermissions || projectPermissions.length === 0 ? (
                <tr>
                  <td className="px-3">{translator.get('messages.no-results-found')}</td>
                </tr>
              ) : (
                projectPermissions.map((permission) => (
                  <tr key={permission.id} className="link-wrapper cursor-pointer">
                    <td className="px-3">
                      <a href={permission.project.viewPath} className="text-gray-900">
                        {permission.project.title}
                        <div className="fs-12 text-gray-500 text-uppercase">
                          {permission.role}
                        </div>
                      </a>
                    </td>
                  </tr>
                ))
              )}
            </DashboxBody>
          </Dashbox>
        </Col>
      </Row>
      <NewProjectModal show={showModal} setShow={setShowModal} />
    </Container>
  );
}

export function Dashboard(props) {
  const {
    projectPermissions,
  } = props;

  return (
    <GlobalWrapper {...props}>
      <DashboardContent projectPermissions={projectPermissions} />
    </GlobalWrapper>
  );
}
