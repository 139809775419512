import { Dropdown } from 'react-bootstrap';
import * as React from 'react';
import { Icon } from '../../../duxfront/duxstrap/components/Icon.jsx';
import { Translator } from '../../../global/translator';
import { BootBox } from '../../../duxfront/duxstrap/vendor/bootbox';
import { deleteJSON } from '../../../duxfront/plugins/dux-utils';
import {
  reportPlotKeysDispatch,
  reportPlotKeysSelector,
  reportPlotMemo,
  reportErrorDispatch,
} from '../report-utils';

export function ReportPlotDelete({ plotKey }) {
  const translator = new Translator();
  const plot = reportPlotMemo(plotKey, ['updatePath']);
  const plotKeys = reportPlotKeysSelector();
  const updatePlotKeys = reportPlotKeysDispatch();
  const updateError = reportErrorDispatch();

  const removePlot = React.useCallback(() => {
    BootBox.confirm({
      size: 'small',
      message: translator.get('messages.are-you-sure'),
      callback: (success) => {
        if (!success) return;

        deleteJSON(
          plot.updatePath,
          {},
          () => updatePlotKeys(plotKeys.filter((key) => key !== plotKey)),
          () => {
            updateError({
              plotKey,
              message: 'Could not remove plot. Please try again later.',
            });
          },
        );
      },
    });
  });

  return (
    <Dropdown.Item onClick={removePlot}>
      <Icon name="times" className="text-primary mr-2" />
      { translator.get('general.remove') }
    </Dropdown.Item>
  );
}
