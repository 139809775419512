import React from 'react';
import { Wrapper } from '@googlemaps/react-wrapper';
import {
  createMap, createMarker, createMarkerWithWindow, setProjectMaps,
} from '../../lib/map';

export default function LocationsMap({
  className = 'h-100',
  locations = [],
  maps = [],
  locationWindow = null,
}) {
  return (
    <div className={className}>
      <Wrapper apiKey="AIzaSyBLgz7nax4w_u-He--taiLugOX6C8mgxGQ" className="w-100 h-100">
        <LocationsMapView
          locations={locations}
          maps={maps}
          locationWindow={locationWindow}
        />
      </Wrapper>
    </div>
  );
}

function LocationsMapView({ locations, maps, locationWindow }) {
  const ref = React.useRef(null);
  const [mapState, setMapState] = React.useState(null);
  const [mapBound, setMapBound] = React.useState(null);
  const [markersHash, setMarkersHash] = React.useState({});

  React.useEffect(() => {
    if (!ref.current) return;
    if (!locations) return;

    const map = mapState || createMap(ref);
    const bounds = mapBound || new global.window.google.maps.LatLngBounds();

    locations.forEach((location) => {
      const previousMarker = markersHash[location.code];

      if (previousMarker && previousMarker.location === location) {
        return;
      }

      markersHash[location.code]?.marker.setMap(null);

      const marker = locationWindow
        ? createMarkerWithWindow(map, location, locationWindow)
        : createMarker(map, location);

      bounds.extend(marker.getPosition());

      setMarkersHash((prev) => ({
        ...prev,
        [location.code]: { marker, location },
      }));
    });

    map.setCenter(bounds.getCenter());
    map.fitBounds(bounds);

    if (map.getZoom() > 15) {
      map.setZoom(15);
    }

    setMapState(map);
    setMapBound(bounds);
  }, [ref, mapState, locations]);

  React.useEffect(() => {
    if (!mapState) return;
    if (!maps) return;

    setProjectMaps(mapState, maps);
  }, [maps]);

  return <div ref={ref} id="map" className="w-100 h-100" />;
}
