import { Dropdown } from 'react-bootstrap';
import * as React from 'react';
import { Icon } from '../../../duxfront/duxstrap/components/Icon.jsx';
import { Project } from '../../../global/project';
import { Translator } from '../../../global/translator';
import {
  reportPlotSelectedKeyDispatch,
  reportModalTypeDispatch,
  MODAL_TYPES,
} from '../report-utils';

export function ReportPlotAdvanced({ plotKey }) {
  const project = new Project();
  const translator = new Translator();
  const updateSelectedPlotKey = reportPlotSelectedKeyDispatch();
  const updateSelectedModalType = reportModalTypeDispatch();
  const hasPermission = project.userCan('partner_actions');

  const launchModal = React.useCallback(() => {
    updateSelectedPlotKey(plotKey);
    updateSelectedModalType(MODAL_TYPES.PLOT_ADVANCED);
  });

  return hasPermission && (
    <Dropdown.Item onClick={launchModal}>
      <Icon name="lock" className="text-primary mr-2" />
      { translator.get('general.advanced') }
    </Dropdown.Item>
  );
}
