/* globals CKEDITOR $ */

export const loadCKEDITOR = function (callback) {
  if (typeof CKEDITOR === 'undefined') {
    $.getScript('https://cdn.ckeditor.com/4.22.1/full/ckeditor.js', () => {
      CKEDITOR.disableAutoInline = true;
      callback();
    });
  } else {
    callback();
  }
};

const textEditorAutoUpdate = function (editor) {
  editor.on('change', (evt) => {
    evt.editor.updateElement();
  });
};

export const fullTextEditor = function (element, height) {
  // eslint-disable-next-line no-param-reassign
  height = typeof height !== 'undefined' ? height : 250;
  textEditorAutoUpdate(CKEDITOR.replace(element, {
    height,
    versionCheck: false,
  }));
};

export const basicTextEditor = function (element, height) {
  // eslint-disable-next-line no-param-reassign
  height = typeof height !== 'undefined' ? height : 250;
  textEditorAutoUpdate(CKEDITOR.replace(element, {
    height,
    versionCheck: false,
    toolbarGroups: [
      {
        name: 'basicstyles',
        groups: ['basicstyles', 'cleanup'],
      },
      {
        name: 'paragraph',
        groups: ['list', 'align'],
      },
      {
        name: 'styles',
        groups: ['styles'],
      },
      {
        name: 'colors',
        groups: ['colors'],
      },
      '/',
      {
        name: 'links',
        groups: ['links'],
      },
      {
        name: 'insert',
        groups: ['insert'],
      },
      {
        name: 'undo',
        groups: ['undo'],
      },
      {
        name: 'tools',
        groups: ['tools'],
      },
      {
        name: 'document',
        groups: ['mode', 'document', 'doctools'],
      },
      {
        name: 'others',
        groups: ['others'],
      },
    ],
    removeButtons: 'Font,Styles,ShowBlocks,Save,NewPage,Templates,Superscript,Subscript,Flash,Smiley,Iframe,PageBreak',
  }));
};

export const modalTextEditor = function (element, height) {
  // eslint-disable-next-line no-param-reassign
  height = typeof height !== 'undefined' ? height : 250;
  textEditorAutoUpdate(CKEDITOR.replace(element, {
    height,
    versionCheck: false,
    toolbarGroups: [
      {
        name: 'colors',
        groups: ['colors'],
      },
      {
        name: 'basicstyles',
        groups: ['basicstyles', 'cleanup'],
      },
      {
        name: 'paragraph',
        groups: ['list', 'align'],
      },
      {
        name: 'links',
        groups: ['links'],
      },
      {
        name: 'undo',
        groups: ['undo'],
      },
      '/',
      {
        name: 'styles',
        groups: ['styles'],
      },
      {
        name: 'insert',
        groups: ['insert'],
      },
      {
        name: 'tools',
        groups: ['tools'],
      },
      {
        name: 'document',
        groups: ['mode', 'document', 'doctools'],
      },
      {
        name: 'others',
        groups: ['others'],
      },
    ],
    removeButtons: 'Font,Styles,ShowBlocks,Save,NewPage,Preview,Print,Templates,Superscript,Subscript,Strike,Flash,Smiley,Iframe,PageBreak',
  }));
};

export const inlineTextEditor = function (element) {
  textEditorAutoUpdate(CKEDITOR.inline(element, {
    versionCheck: false,
    toolbarGroups: [
      {
        name: 'colors',
        groups: ['colors'],
      },
      {
        name: 'basicstyles',
        groups: ['basicstyles', 'cleanup'],
      },
      {
        name: 'paragraph',
        groups: ['list', 'align'],
      },
      {
        name: 'links',
        groups: ['links'],
      },
      {
        name: 'styles',
        groups: ['styles'],
      },
    ],
    removeButtons: 'Font,Styles,ShowBlocks,Save,NewPage,Preview,Print,Templates,Superscript,Subscript,Strike,Flash,Smiley,Iframe,PageBreak',
  }));
};

if (typeof $ !== 'undefined') {
  $(() => {
    const defaultHeight = 250;
    $('.text-editor').each(function () {
      const $this = $(this);
      let height = defaultHeight;
      if ($this.attr('data-editor-height')) {
        height = parseInt($this.attr('data-editor-height'), 10);
      }
      loadCKEDITOR(() => {
        basicTextEditor($this[0], height);
      });
    });
    $('.text-editor-full').each(function () {
      const $this = $(this);
      let height = defaultHeight;
      if ($this.attr('data-editor-height')) {
        height = parseInt($this.attr('data-editor-height'), 10);
      }
      loadCKEDITOR(() => {
        fullTextEditor($this[0], height);
      });
    });
    $('.text-editor-modal').each(function () {
      const $this = $(this);
      let height = defaultHeight;
      if ($this.attr('data-editor-height')) {
        height = parseInt($this.attr('data-editor-height'), 10);
      }
      loadCKEDITOR(() => {
        modalTextEditor($this[0], height);
      });
    });
    $('.text-editor-inline').each(() => {
      loadCKEDITOR(function () {
        inlineTextEditor($(this)[0]);
      });
    });
  });
}
