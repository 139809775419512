import * as React from 'react';

export function Spacer(props) {
  const {
    size = 1,
    block = false,
    className = '',
  } = props;

  const classes = [className, `m-${size}`, 'p-0'];

  if (block) {
    classes.push('w-100');
  } else {
    classes.push('d-inline-block');
  }

  return (
    <div className={classes.join(' ').trim()}> </div>
  );
}
