import * as React from 'react';
import { Col, Modal, Table } from 'react-bootstrap';
import { GlobalWrapper } from '../GlobalWrapper.jsx';
import { Translator } from '../../global/translator';
import { Button } from '../../duxfront/duxstrap/components/Button.jsx';
import { Icon } from '../../duxfront/duxstrap/components/Icon.jsx';
import { Spacer } from '../../duxfront/duxstrap/components/Spacer.jsx';
import { globalDispatch, globalSelector } from '../../duxfront/plugins/dux-redux';
import { JSONEditor } from '../../duxfront/duxdash/components/JSONEditor.jsx';
import {
  deleteJSON, loadingOverlay, parseForm, patchJSON, postJSON, updateByKey, removeByKey,
} from '../../duxfront/plugins/dux-utils';
import { Project } from '../../global/project';
import { Authenticity } from '../../global/authenticity';
import { Form, HiddenInput, Select } from '../../duxfront/duxstrap/components/Form.jsx';
import { BootBox } from '../../duxfront/duxstrap/vendor/bootbox';

function LocationModuleInitialLoader({ location, locationModules }) {
  const updateLocation = globalDispatch('selectedLocation');
  const updateLocationModules = globalDispatch('locationModules');

  React.useEffect(() => {
    updateLocation(location);
    updateLocationModules(locationModules);
  });

  return null;
}

function LocationModulesTable() {
  const translator = new Translator();
  const project = new Project();
  const locationModules = globalSelector('locationModules');
  const updateSelectedLocationModule = globalDispatch('selectedLocationModule');
  const canAdd = project.userCan('manage_location_modules');

  const launchModal = React.useCallback((module = {}) => {
    updateSelectedLocationModule(module);
  });

  return (
    <Table size="sm" className="m-0" hover>
      <tbody>
        { canAdd && (
          <tr key="location-module-add-button">
            <td className="px-3 text-center">
              <Button size="xs" variant="primary" onClick={() => launchModal()} style={{ minWidth: '150px' }} withIcon>
                <Icon name="plus" />
                { translator.get('general.add').toUpperCase() }
              </Button>
            </td>
          </tr>
        )}
        { locationModules && locationModules.length > 0
          ? locationModules.map((module) => (
            <tr key={module.code} className="cursor-pointer" onClick={() => launchModal(module)}>
              <td className="px-3">
                <div className="font-weight-accent text-primary">{module.title}</div>
                <div className="fs-12 text-gray-500">{module.code}</div>
              </td>
            </tr>
          ))
          : (
            <tr>
              <td className="px-3">
                <p>{translator.get('messages.no-results-found')}</p>
              </td>
            </tr>
          )}
      </tbody>
    </Table>
  );
}

function LocationModuleModal() {
  const translator = new Translator();
  const project = new Project();
  const authenticityToken = new Authenticity().token();
  const self = React.createRef();
  const formId = 'location_module';
  const selectedLocation = globalSelector('selectedLocation');
  const selectedLocationModule = globalSelector('selectedLocationModule');
  const updateSelectedLocationModule = globalDispatch('selectedLocationModule');
  const locationModules = globalSelector('locationModules');
  const updateLocationModules = globalDispatch('locationModules');
  const isUpdate = !!selectedLocationModule?.code;

  const closeModal = React.useCallback(() => {
    updateSelectedLocationModule(null);
  });

  const submitLocationModule = React.useCallback((e) => {
    e.preventDefault();

    loadingOverlay(self.current, 'show');

    const httpCall = isUpdate ? patchJSON : postJSON;
    const submissionUrl = selectedLocationModule?.updatePath || project.locationModulesPath;
    const form = parseForm(e.target);

    httpCall(
      submissionUrl,
      form,
      (data) => {
        loadingOverlay(self.current, 'hide');
        updateLocationModules(updateByKey(locationModules, data.object));
        closeModal();
      },
    );
  });

  const destroyLocationModule = React.useCallback(() => {
    BootBox.confirm({
      size: 'small',
      message: translator.get('messages.are-you-sure'),
      callback: (success) => {
        if (!success) return;

        loadingOverlay(self.current, 'show');

        deleteJSON(
          selectedLocationModule.updatePath,
          {},
          (data) => {
            loadingOverlay(self.current, 'hide');
            updateLocationModules(removeByKey(locationModules, data.object.key));
            closeModal();
          },
        );
      },
    });
  });

  return (
    <Modal centered show={!!selectedLocationModule} onHide={closeModal}>
      <Modal.Header closeButton onHide={closeModal}>
        <Modal.Title>
          {isUpdate ? translator.get('general.update') : translator.get('general.add')}
          {translator.get('titles.module')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body ref={self} className="pt-2 px-3">
        <Form
          id={formId}
          onSubmit={submitLocationModule}
          authenticityToken={authenticityToken}
          validate
          withBorders
        >
          <HiddenInput id="location_code" formId={formId} defaultValue={selectedLocation?.code} />
          { !isUpdate && (
            <Col sm={12}>
              <Select
                id="identifier"
                formId={formId}
                label={translator.get('general.type')}
                defaultValue={selectedLocationModule?.identifier}
                options={project.locationModuleOptions}
              />
            </Col>
          )}
          { isUpdate && (
            <Col md={12}>
              <JSONEditor
                collapsed
                formId={formId}
                inputId="configs"
                json={selectedLocationModule?.config}
                style={{ maxHeight: '40vh' }}
                forceSnakeCase
              />
            </Col>
          )}
          <Spacer block />
          <Col sm={6}>
            {
              isUpdate
                ? (
                  <Button size="sm" variant="danger" onClick={destroyLocationModule} withIcon block>
                    <Icon name="trash" />
                    { translator.get('general.delete') }
                  </Button>
                )
                : (
                  <Button size="sm" variant="gray-200" onClick={closeModal} withIcon block>
                    <Icon name="times" />
                    { translator.get('general.cancel') }
                  </Button>
                )
            }
          </Col>
          <Col sm={6}>
            <Button size="sm" variant="primary" type="submit" withIcon block>
              <Icon name="check" />
              { translator.get('general.save') }
            </Button>
          </Col>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export function LocationModules(props) {
  const {
    location,
    locationModules,
  } = props;

  return (
    <GlobalWrapper {...props}>
      <LocationModuleInitialLoader location={location} locationModules={locationModules} />
      <LocationModulesTable />
      <LocationModuleModal />
    </GlobalWrapper>
  );
}
