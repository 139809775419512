import { Dropdown } from 'react-bootstrap';
import * as React from 'react';
import { Icon } from '../../../duxfront/duxstrap/components/Icon.jsx';
import { Translator } from '../../../global/translator';
import {
  MODAL_TYPES, reportModalTypeDispatch, reportPlotSelectedKeyDispatch,
  reportPlotSelector,
} from '../report-utils';

export function ReportPlotGeneral({ plotKey }) {
  const translator = new Translator();
  const plot = reportPlotSelector(plotKey);
  const updateSelectedPlotKey = reportPlotSelectedKeyDispatch();
  const updateSelectedModalType = reportModalTypeDispatch();

  const launchModal = React.useCallback((modalType) => {
    updateSelectedPlotKey(plot.key);
    updateSelectedModalType(modalType);
  });

  return (
    <Dropdown.Item onClick={() => launchModal(MODAL_TYPES.PLOT_GENERAL)}>
      <Icon name="cogs" className="text-primary mr-2" />
      { translator.get('general.settings') }
    </Dropdown.Item>
  );
}
