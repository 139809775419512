import * as React from 'react';
import { ReportFormWrapper } from '../report_base/ReportFormWrapper.jsx';
import {
  reportModalTypeDispatch,
  reportPlotDispatch, reportPlotSelectedKeyDispatch,
  reportPlotSelectedKeySelector,
  reportPlotSelector,
} from '../report-utils';

export function ReportPlotFormWrapper({
  formId,
  children,
  onClose = null,
  onSave = null,
  onNext = null,
  onRemove = null,
  prepareData = null,
  afterSave = null,
  hidden = false,
}) {
  const reportPlotSelectedKey = reportPlotSelectedKeySelector();
  const plot = reportPlotSelector(reportPlotSelectedKey);
  const self = React.createRef();
  const updateReportPlot = reportPlotDispatch(reportPlotSelectedKey);
  const updateReportModalType = reportModalTypeDispatch();
  const updateReportPlotSelectedKey = reportPlotSelectedKeyDispatch();

  const closeModal = onClose || React.useCallback(() => {
    updateReportModalType(null);
    updateReportPlotSelectedKey(null);
  });

  const onResponse = React.useCallback((data) => {
    updateReportPlot(data.object);
    (afterSave || closeModal)(data);
  });

  return (
    <ReportFormWrapper
      self={self}
      formId={formId}
      onClose={closeModal}
      onSave={onSave}
      onNext={onNext}
      onRemove={onRemove}
      prepareData={prepareData}
      afterSave={onResponse}
      updatePath={plot?.updatePath}
      hidden={hidden}
    >
      { children }
    </ReportFormWrapper>
  );
}
