/* globals window document $ moment t */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */

import { Duxstrap } from './duxfront/duxstrap/duxstrap-core';
import { Duxdash } from './duxfront/duxdash/duxdash-core';
import { Cognito } from './duxfront/plugins/dux-cognito';
import {
  getUrlParameter, clipboardCopy, IframeLoader, postFormData,
} from './duxfront/plugins/dux-utils';
import { Translator } from './global/translator';
import {} from './server_rendering';

Duxstrap.init();
Duxdash.init();

// ******************* GLOBAL META TAGS *******************

global.dev_environment = true;
global.decimal_separator = $('meta[name="decimal_separator"]').attr('content');
global.getUrlParameter = getUrlParameter;
global.iframeLoader = IframeLoader;
global.clipboardCopy = clipboardCopy;
global.postFormData = postFormData;

const cognito_pool_id = $('meta[name="cognito_pool_id"]').attr('content');
const cognito_client_id = $('meta[name="cognito_client_id"]').attr('content');
const cognito_endpoint = $('meta[name="cognito_endpoint"]').attr('content');
const cognito_cookie_domain = $('meta[name="cognito_cookie_domain"]').attr('content');
const cognito_redirect_uri = $('meta[name="cognito_redirect_uri"]').attr('content');
const cognito_signout_uri = $('meta[name="cognito_signout_uri"]').attr('content');

if ($('meta[name="dev_environment"]').attr('content') === 'false') {
  global.dev_environment = false;
}

// ********************** TRANSLATOR ***********************

global.t = new Translator();

// ****************** AWS COGNITO SESSION ******************

if (typeof cognito_pool_id !== 'undefined') {
  global.cognito = new Cognito(
    cognito_pool_id,
    cognito_client_id,
    cognito_endpoint,
    cognito_cookie_domain,
    cognito_redirect_uri,
    cognito_signout_uri,
  );

  $(() => {
    global.cognito.init();
  });
}

// ***************** CHECK CONNECTION *****************

$(() => {
  Duxdash.check_online('No internet connection. Please, connect and try again.');
});

// ************ DATE RANGE PICKER CONFIGS **************

global.drPickerConfigSingleDate = function () {
  return {
    singleDatePicker: true,
    timePicker: false,
    startDate: moment().startOf('day'),
    opens: 'center',
    locale: {
      format: t.get('formats.basic-date-js'),
    },
  };
};

global.drPickerConfigSingleDateTime = function () {
  return {
    singleDatePicker: true,
    timePicker: true,
    timePicker24Hour: true,
    timePickerIncrement: 5,
    startDate: moment().startOf('minute'),
    opens: 'center',
    locale: {
      format: t.get('formats.basic-date-time-js'),
    },
  };
};

global.drPickerConfigReports = function () {
  const now = moment().startOf('minute');
  const remainder = (now.minute() % 5);
  const end = now.subtract(remainder, 'minutes');

  return {
    timePicker: true,
    timePicker24Hour: true,
    timePickerIncrement: 5,
    startDate: now.clone().subtract(24, 'hours'),
    endDate: now,
    opens: 'left',
    alwaysShowCalendars: true,
    locale: {
      format: t.get('formats.basic-date-time-js').replace(':ss', ''),
    },
    ranges: {
      '06 hours': [end.clone().subtract(6, 'hours'), end],
      '12 hours': [end.clone().subtract(12, 'hours'), end],
      '24 hours': [end.clone().subtract(24, 'hours'), end],
      '07 Days': [end.clone().subtract(7, 'days'), end],
      '30 Days': [end.clone().subtract(30, 'days'), end],
      '03 months': [end.clone().subtract(3, 'months'), end],
      '06 months': [end.clone().subtract(6, 'months'), end],
    },
  };
};

// **************** HISTORY NAVIGATION ****************
$(() => {
  $('.history-back').click((e) => {
    if (window.history.length > 1) {
      if (document.referrer !== window.location.href) {
        if (document.referrer.includes('core.duxdata.io') || document.referrer.includes('localhost:3000')) {
          e.preventDefault();
          window.history.go(-1);
          return false;
        }
      }
    }
  });
});

// **************** NUMBER FORMATTING ****************
global.formatDecimal = function (decimal_value, precision) {
  let value = decimal_value;

  if (value !== null) {
    if (precision) {
      value = value.toFixed(precision);
    } else {
      value = value.toString();
    }
    if (global.decimal_separator) {
      value = value.replace('.', global.decimal_separator);
    }
  }
  return value;
};
