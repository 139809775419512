import * as React from 'react';

export function LocationLink(props) {
  const {
    children,
    latitude,
    longitude,
    ...forwarded
  } = props;

  return (
    <a
      href={`https://www.google.com/maps?q=${latitude},${longitude}`}
      target="_"
      rel="noreferrer"
      {...forwarded}
    >
      { children }
    </a>
  );
}
