import * as React from 'react';
import {
  dumpDate,
  parseDate,
  reportDateRangeSelector, reportErrorDispatch,
  reportPlotDataDispatch,
  reportPlotInformationDispatch,
  reportPlotSelector,
} from '../report-utils';
import { getJSON, loadingOverlay } from '../../../duxfront/plugins/dux-utils';

export function ReportPlotDataLoader({ plotKey, boxContentRef }) {
  const plot = reportPlotSelector(plotKey, ['dataPath', 'dataConfigVersion']);
  const reportDateRange = reportDateRangeSelector();
  const updatePlotData = reportPlotDataDispatch(plotKey);
  const updatePlotInformation = reportPlotInformationDispatch(plotKey);
  const updateError = reportErrorDispatch(plotKey);
  const startDate = parseDate(reportDateRange?.startDate);
  const endDate = parseDate(reportDateRange?.endDate);

  React.useEffect(() => {
    if (!boxContentRef.current) return;
    if (!startDate || !endDate) return;

    // Set the loading overlay with z-index that is right below the modal backdrop (1040)
    loadingOverlay(boxContentRef.current, 'show', { zIndex: 1030 });

    getJSON(
      plot.dataPath,
      {
        startDate: dumpDate(startDate),
        endDate: dumpDate(endDate),
      },
      (data) => {
        loadingOverlay(boxContentRef.current, 'hide');

        if (data.status) {
          updatePlotInformation(data.information);
          updatePlotData({
            data: data.data,
            startDate: dumpDate(startDate),
            endDate: dumpDate(endDate),
            dataConfigVersion: plot.dataConfigVersion,
            // This useful for custom plots.
            // It allows overriding the initial plot config at the time of loading the data.
            setup: data.setup,
          });
        } else {
          updateError({
            plotKey,
            message: 'Failed to retrieve data.',
          });
        }
      },
      (e) => {
        loadingOverlay(boxContentRef.current, 'hide');
        updateError({
          plotKey,
          message: `Failed to retrieve data.<pre> ${e.responseText} </pre>`,
        });
      },
    );
  });

  return null;
}
