import { Col } from 'react-bootstrap';
import * as React from 'react';
import { Input, Select } from '../../../duxfront/duxstrap/components/Form.jsx';
import { Translator } from '../../../global/translator';
import { ReportPlotFormWrapper } from './ReportPlotFormWrapper.jsx';
import { ReportPlotModalWrapper } from './ReportPlotModalWrapper.jsx';
import {
  MODAL_TYPES,
  reportPlotOptionsSelector,
  reportPlotSelectedKeySelector,
  reportPlotSelector, WIDGET_TYPES,
} from '../report-utils';

export function ReportPlotGeneralForm() {
  const translator = new Translator();
  const plotOptions = reportPlotOptionsSelector();
  const reportPlotSelectedKey = reportPlotSelectedKeySelector();
  const plot = reportPlotSelector(reportPlotSelectedKey);
  const formId = 'plot_settings';
  const title = `${translator.get('general.edit')} ${translator.get('general.settings')}`;

  return plotOptions && (
    <ReportPlotModalWrapper
      title={title}
      modalType={MODAL_TYPES.PLOT_GENERAL}
    >
      <ReportPlotFormWrapper formId={formId}>
        <Col sm={12}>
          <Input
            id="title"
            formId={formId}
            label={translator.get('general.title')}
            defaultValue={plot?.title}
            placeholder="Daily Temperature"
            validation="required"
          />
        </Col>
        <Col sm={6}>
          <Select
            id="width"
            formId={formId}
            label={translator.get('general.width')}
            defaultValue={plot?.width}
            options={plotOptions.plotWidthOptions}
          />
        </Col>
        <Col sm={6}>
          <Select
            id="height"
            formId={formId}
            label={translator.get('general.height')}
            defaultValue={plot?.height}
            options={plotOptions.plotHeightOptions}
          />
        </Col>
        <Col sm>
          <Input
            id="order"
            formId={formId}
            label={translator.get('general.position')}
            defaultValue={plot?.order}
            placeholder="99"
            validation="required"
          />
        </Col>
        { plot?.widget === WIDGET_TYPES.INTERACTIVE && (
          <Col sm={6}>
            <Select
              id="time_interval"
              formId={formId}
              label={translator.get('general.time-unit')}
              defaultValue={plot?.timeInterval || 'auto'}
              options={plotOptions.plotTimeIntervalOptions}
            />
          </Col>
        )}
        { plot?.widget === WIDGET_TYPES.TABLE && (
          <>
            <Col sm={4}>
              <Input
                id="date_header"
                formId={formId}
                label={translator.get('general.date-header')}
                defaultValue={plot?.dateHeader}
                validation="required"
              />
            </Col>
            <Col sm={4}>
              <Select
                id="date_format"
                formId={formId}
                label={translator.get('general.date-format')}
                defaultValue={plot?.dateFormat}
                options={plotOptions.plotDateFormatOptions}
              />
            </Col>
          </>
        )}
        {/* <Col sm={6}> */}
        {/*  <Checkbox */}
        {/*    id="group_intervals" */}
        {/*    formId={formId} */}
        {/*    label={translator.get('general.group-intervals')} */}
        {/*    defaultChecked={plot?.groupIntervals} */}
        {/*    block */}
        {/*  /> */}
        {/* </Col> */}
      </ReportPlotFormWrapper>
    </ReportPlotModalWrapper>
  );
}
