import * as React from 'react';
import { JSONEditor as VanillaJSONEditor, stringifyJSONPath } from 'vanilla-jsoneditor';
import _, { snakeCase } from 'lodash';
import { HiddenInput } from '../../duxstrap/components/Form.jsx';
import { deepTransformKeys } from '../../plugins/dux-utils';

export function JSONEditor(props) {
  const {
    style = null,
    inputId = null,
    formId = null,
    forceSnakeCase = false,
  } = props;

  const refContainer = React.useRef(null);
  const refEditor = React.useRef(null);
  const refInput = React.useRef(null);

  const parsedProps = { ...props };

  parsedProps.navigationBar = parsedProps.navigationBar || false;

  if (parsedProps.json) {
    let json = typeof parsedProps.json === 'string' ? JSON.parse(parsedProps.json) : parsedProps.json;

    if (forceSnakeCase) json = deepTransformKeys(json, snakeCase);

    parsedProps.content = { json };
  }

  const findMenuItem = (title, menuItem) => {
    if (!menuItem) { return; }

    if (menuItem.text?.toLowerCase() === title.toLowerCase()) return menuItem;
    if (!menuItem.items) { return; }

    for (const item of menuItem.items) {
      const itemFound = findMenuItem(title, item);
      if (itemFound) return itemFound;
    }
  };

  parsedProps.onRenderContextMenu = (items) => {
    const selectedItemTitles = ['value', 'object', 'array', 'duplicate', 'remove'];
    return selectedItemTitles.map((itemTitle) => items.map(
      (item) => findMenuItem(itemTitle, item),
    ).find((item) => !!item));
  };

  parsedProps.onSelect = (selection) => {
    if (!selection.path) return;

    const stringPath = `/${stringifyJSONPath(selection.path)}`;
    const editorValue = refEditor.current.get().json;
    const itemValue = _.get(editorValue, selection.path);

    if (Array.isArray(itemValue) && itemValue.length === 0) {
      refEditor.current.patch([
        // https://jsonpatch.com/
        { op: 'add', path: `${stringPath}/-`, value: '' },
      ]);
    }
  };

  parsedProps.onChange = (content) => {
    if (refInput.current) {
      if (content.json) refInput.current.value = JSON.stringify(content.json);
      if (content.text) refInput.current.value = content.text;
    }
  };

  React.useEffect(() => {
    refEditor.current = new VanillaJSONEditor({
      target: refContainer.current,
      props: parsedProps,
    });

    if (parsedProps.collapsed) refEditor.current.expand((path) => path.length === 0);
    if (inputId) parsedProps.onChange(parsedProps.content);

    return () => {
      if (refEditor.current) {
        refEditor.current.destroy();
        refEditor.current = null;
      }
    };
  }, []);

  return (
    <>
      { inputId
        && <HiddenInput ref={refInput} id={inputId} formId={formId} type="text" /> }
      <div className="jsoneditor-react" ref={refContainer} style={style} />
    </>
  );
}
