import * as React from 'react';
import { ReportInteractivePlot } from '../report_interactive_plot/ReportInteractivePlot.jsx';
import { ReportInteractiveCustomPlot } from '../report_interactive_plot/ReportInteractiveCustomPlot.jsx';
import { ReportInteractivePieCustomPlot } from '../report_interactive_plot/ReportInteractivePieCustomPlot.jsx';
import { ReportTablePlot } from '../report_table_plot/ReportTablePlot.jsx';
import { ReportTableCustomPlot } from '../report_table_plot/ReportTableCustomPlot.jsx';
import { ReportWindRosePlot } from '../report_plot_other/ReportWindRosePlot.jsx';
import { reportPlotSelector, WIDGET_TYPES } from '../report-utils';
import { ReportTextEditorPlot } from '../report_plot_text_editor/ReportTextEditorPlot.jsx';
import { ReportMapPlot } from '../report_plot_map/ReportMapPlot.jsx';

export const ReportPlot = React.memo(({ plotKey }) => {
  const plot = reportPlotSelector(plotKey, ['widget']);

  switch (plot.widget) {
    case WIDGET_TYPES.INTERACTIVE:
      return <ReportInteractivePlot plotKey={plotKey} />;
    case WIDGET_TYPES.INTERACTIVE_CUSTOM:
      return <ReportInteractiveCustomPlot plotKey={plotKey} />;
    case WIDGET_TYPES.INTERACTIVE_PIE_CUSTOM:
      return <ReportInteractivePieCustomPlot plotKey={plotKey} />;
    case WIDGET_TYPES.TABLE:
      return <ReportTablePlot plotKey={plotKey} />;
    case WIDGET_TYPES.TABLE_CUSTOM:
      return <ReportTableCustomPlot plotKey={plotKey} />;
    case WIDGET_TYPES.WIND_ROSE:
      return <ReportWindRosePlot plotKey={plotKey} />;
    case WIDGET_TYPES.TEXT_EDITOR:
      return <ReportTextEditorPlot plotKey={plotKey} />;
    case WIDGET_TYPES.MAP:
      return <ReportMapPlot plotKey={plotKey} />;
    default:
      return null;
  }
});
