import * as React from 'react';
import { Col } from 'react-bootstrap';
import { JSONEditor } from '../../../duxfront/duxdash/components/JSONEditor.jsx';
import { Translator } from '../../../global/translator';
import { ReportPlotModalWrapper } from './ReportPlotModalWrapper.jsx';
import { ReportPlotFormWrapper } from './ReportPlotFormWrapper.jsx';
import {
  MODAL_TYPES,
  reportPlotSelectedKeySelector,
  reportPlotSelector,
} from '../report-utils';

export function ReportPlotAdvancedForm() {
  const translator = new Translator();
  const reportPlotSelectedKey = reportPlotSelectedKeySelector();
  const plot = reportPlotSelector(reportPlotSelectedKey);
  const formId = 'plot_advanced';
  const title = translator.get('general.advanced');

  return plot && (
    <ReportPlotModalWrapper
      title={title}
      modalType={MODAL_TYPES.PLOT_ADVANCED}
    >
      <ReportPlotFormWrapper formId={formId}>
        <Col>
          <JSONEditor
            collapsed
            formId={formId}
            inputId="config"
            json={plot.config}
            style={{ maxHeight: '40vh' }}
            forceSnakeCase
          />
        </Col>
      </ReportPlotFormWrapper>
    </ReportPlotModalWrapper>
  );
}
