import * as React from 'react';

export function Icon(props) {
  const {
    name,
    offset = 0,
    className = '',
    ...forwarded
  } = props;

  return (
    <i className={`fa fa-${name} fa-offset-${offset} icon ${className}`} {...forwarded} />
  );
}
