import * as React from 'react';
import { Modal } from 'react-bootstrap';
import {
  reportModalTypeDispatch,
  reportModalTypeSelector,
} from '../report-utils';

export function ReportModalWrapper({
  modalType, title, children, onClose = null, show = true, size = 'md',
}) {
  const selectedModalType = reportModalTypeSelector();
  const updateReportModalType = reportModalTypeDispatch();
  const active = show && selectedModalType === modalType;

  const closeModal = onClose || React.useCallback(() => {
    updateReportModalType(null);
  });

  return (
    <Modal centered show={!!active} onHide={closeModal} size={size}>
      <Modal.Header closeButton onHide={closeModal}>
        <Modal.Title>{ title }</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0 pt-2">
        { children }
      </Modal.Body>
    </Modal>
  );
}
