import { Dropdown } from 'react-bootstrap';
import * as React from 'react';
import { Icon } from '../../../duxfront/duxstrap/components/Icon.jsx';
import { Translator } from '../../../global/translator';
import { reportPlotDispatch, reportPlotMemo } from '../report-utils';

export function ReportPlotRefreshData({ plotKey }) {
  const translator = new Translator();
  const fullPlot = reportPlotMemo(plotKey);
  const updatePlot = reportPlotDispatch(plotKey);

  const reloadPlotData = React.useCallback(() => {
    fullPlot.dataConfigVersion = null;
    updatePlot(fullPlot);
  });

  return (
    <Dropdown.Item onClick={reloadPlotData}>
      <Icon name="refresh" className="text-primary mr-2" />
      { translator.get('general.update') }
    </Dropdown.Item>
  );
}
