import * as React from 'react';
import { Col, Modal } from 'react-bootstrap';
import { sortBy } from 'lodash';
import { GlobalWrapper } from '../GlobalWrapper.jsx';
import { Dashbox, DashboxBody, DashboxTitle } from '../../duxfront/duxdash/components/Dashbox.jsx';
import { Form, Input, ListEditor } from '../../duxfront/duxstrap/components/Form.jsx';
import { BootBox } from '../../duxfront/duxstrap/vendor/bootbox';
import { Button } from '../../duxfront/duxstrap/components/Button.jsx';
import { Icon } from '../../duxfront/duxstrap/components/Icon.jsx';
import { Spacer } from '../../duxfront/duxstrap/components/Spacer.jsx';
import {
  deleteJSON, getJSON, loadingOverlay, parseForm, patchJSON, postJSON, updateByKey, removeByKey,
} from '../../duxfront/plugins/dux-utils';
import { globalDispatch, globalSelector } from '../../duxfront/plugins/dux-redux';
import { Project } from '../../global/project';
import { Translator } from '../../global/translator';
import { Authenticity } from '../../global/authenticity';

function NotificationListInitialLoader({ notificationLists }) {
  const updateNotificationLists = globalDispatch('notificationLists');

  React.useEffect(() => updateNotificationLists(notificationLists));

  return null;
}

function NotificationListsTable() {
  const translator = new Translator();
  const project = new Project();
  const self = React.createRef();
  const notificationLists = globalSelector('notificationLists');
  const updateSelectedNotificationList = globalDispatch('selectedNotificationList');
  const canEdit = project.userCan('manage_project');

  const launchModal = React.useCallback((list = null) => {
    if (!canEdit) return;
    if (list) return updateSelectedNotificationList(list);

    loadingOverlay(self.current, 'show');

    getJSON(
      project.notificationListsPath,
      {},
      (data) => {
        loadingOverlay(self.current, 'hide');
        updateSelectedNotificationList(data.object);
      },
    );
  });

  return (
    <Dashbox className="h-100">
      <DashboxTitle title={translator.get('general.notification_lists')}>
        { canEdit && (
          <Button rounded icon size="sm" variant="outline-primary" onClick={() => launchModal()}>
            <Icon name="plus" offset={1} />
          </Button>
        )}
      </DashboxTitle>
      <DashboxBody ref={self} table style={{ maxHeight: '300px' }}>
        { notificationLists && notificationLists.length > 0
          ? sortBy(notificationLists, ['title']).map((list) => (
            <tr key={list.code} className="cursor-pointer" onClick={() => launchModal(list)}>
              <td className="px-3">
                <div className="font-weight-accent">{list.title}</div>
                <div className="fs-12 text-gray-500">{list.description}</div>
              </td>
            </tr>
          ))
          : (
            <tr>
              <td className="px-3">
                <p>{translator.get('messages.no-results-found')}</p>
              </td>
            </tr>
          )}
      </DashboxBody>
    </Dashbox>
  );
}

function NotificationListModal() {
  const translator = new Translator();
  const project = new Project();
  const authenticityToken = new Authenticity().token();
  const self = React.createRef();
  const formId = 'notification_list';
  const selectedNotificationList = globalSelector('selectedNotificationList');
  const updateSelectedNotificationList = globalDispatch('selectedNotificationList');
  const notificationLists = globalSelector('notificationLists');
  const updateNotificationLists = globalDispatch('notificationLists');
  const isUpdate = !!selectedNotificationList?.code;
  const canEdit = project.userCan('manage_project');

  const closeModal = React.useCallback(() => {
    updateSelectedNotificationList(null);
  });

  const submitNotificationList = React.useCallback((e) => {
    e.preventDefault();

    loadingOverlay(self.current, 'show');

    const httpCall = isUpdate ? patchJSON : postJSON;
    const submissionUrl = selectedNotificationList?.submissionUrl || project.notificationListsPath;
    const form = parseForm(e.target);

    httpCall(
      submissionUrl,
      form,
      (data) => {
        loadingOverlay(self.current, 'hide');
        updateNotificationLists(updateByKey(notificationLists, data.object));
        closeModal();
      },
    );
  });

  const destroyNotificationList = React.useCallback(() => {
    BootBox.confirm({
      size: 'small',
      message: translator.get('messages.are-you-sure'),
      callback: (success) => {
        if (!success) return;

        loadingOverlay(self.current, 'show');

        deleteJSON(
          selectedNotificationList.submissionUrl,
          {},
          (data) => {
            loadingOverlay(self.current, 'hide');
            updateNotificationLists(removeByKey(notificationLists, data.object.key));
            closeModal();
          },
        );
      },
    });
  });

  return (
    <Modal centered show={!!selectedNotificationList} onHide={closeModal}>
      <Modal.Header closeButton onHide={closeModal}>
        <Modal.Title>
          {isUpdate ? translator.get('general.update') : translator.get('general.add')}
          {translator.get('general.notification_list')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body ref={self} className="pt-2 px-3">
        <Form
          id={formId}
          onSubmit={submitNotificationList}
          authenticityToken={authenticityToken}
          validate
          withBorders
        >
          <Col sm={12}>
            <Input
              id="title"
              formId={formId}
              label={translator.get('general.title')}
              defaultValue={selectedNotificationList?.title}
              readOnly={!canEdit}
              placeholder="Department X"
              validation="required"
            />
          </Col>
          <Col sm={12}>
            <ListEditor
              id="email_list"
              formId={formId}
              defaultValues={selectedNotificationList?.emailList}
              label={translator.get('general.email_notifications')}
            />
          </Col>
          <Col sm={12}>
            <ListEditor
              id="sms_list"
              formId={formId}
              defaultValues={selectedNotificationList?.smsList}
              label={translator.get('general.sms_notifications')}
            />
          </Col>
          { canEdit && (
            <>
              <Spacer block />
              <Col sm={6}>
                {
                  isUpdate
                    ? (
                      <Button size="sm" variant="danger" onClick={destroyNotificationList} withIcon block>
                        <Icon name="trash" />
                        { translator.get('general.delete').toUpperCase() }
                      </Button>
                    )
                    : (
                      <Button size="sm" variant="gray-200" onClick={closeModal} withIcon block>
                        <Icon name="times" />
                        { translator.get('general.cancel').toUpperCase() }
                      </Button>
                    )
                }
              </Col>
              <Col sm={6}>
                <Button size="sm" variant="primary" type="submit" withIcon block>
                  <Icon name="check" />
                  { translator.get('general.save').toUpperCase() }
                </Button>
              </Col>
            </>
          )}
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export function NotificationLists(props) {
  const {
    notificationLists,
  } = props;

  return (
    <GlobalWrapper {...props}>
      <NotificationListInitialLoader notificationLists={notificationLists} />
      <NotificationListsTable />
      <NotificationListModal />
    </GlobalWrapper>
  );
}
