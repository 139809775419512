import * as React from 'react';
import { Col, Modal } from 'react-bootstrap';
import { sortBy } from 'lodash';
import { GlobalWrapper } from '../GlobalWrapper.jsx';
import { Dashbox, DashboxBody, DashboxTitle } from '../../duxfront/duxdash/components/Dashbox.jsx';
import { Form, Input, ListEditor } from '../../duxfront/duxstrap/components/Form.jsx';
import { BootBox } from '../../duxfront/duxstrap/vendor/bootbox';
import { Button } from '../../duxfront/duxstrap/components/Button.jsx';
import { Icon } from '../../duxfront/duxstrap/components/Icon.jsx';
import { Spacer } from '../../duxfront/duxstrap/components/Spacer.jsx';
import {
  deleteJSON, getJSON, loadingOverlay, parseForm, patchJSON, postJSON, updateByKey, removeByKey,
} from '../../duxfront/plugins/dux-utils';
import { globalDispatch, globalSelector } from '../../duxfront/plugins/dux-redux';
import { Project } from '../../global/project';
import { Translator } from '../../global/translator';
import { Authenticity } from '../../global/authenticity';

function ObservationGroupInitialLoader({ observationGroups }) {
  const updateObservationGroups = globalDispatch('observationGroups');

  React.useEffect(() => updateObservationGroups(observationGroups));

  return null;
}

function ObservationGroupsTable() {
  const translator = new Translator();
  const project = new Project();
  const self = React.createRef();
  const observationGroups = globalSelector('observationGroups');
  const updateSelectedObservationGroup = globalDispatch('selectedObservationGroup');
  const canEdit = project.userCan('manage_project');

  const launchModal = React.useCallback((list = null) => {
    if (!canEdit) return;
    if (list) return updateSelectedObservationGroup(list);

    loadingOverlay(self.current, 'show');

    getJSON(
      project.observationGroupsPath,
      {},
      (data) => {
        loadingOverlay(self.current, 'hide');
        updateSelectedObservationGroup(data.object);
      },
    );
  });

  return (
    <Dashbox className="h-100">
      <DashboxTitle title={translator.get('general.observation_groups')}>
        { canEdit && (
          <Button rounded icon size="sm" variant="outline-primary" onClick={() => launchModal()}>
            <Icon name="plus" offset={1} />
          </Button>
        )}
      </DashboxTitle>
      <DashboxBody ref={self} table style={{ maxHeight: '300px' }}>
        { observationGroups && observationGroups.length > 0
          ? sortBy(observationGroups, ['name']).map((group) => (
            <tr key={group.code} className="cursor-pointer" onClick={() => launchModal(group)}>
              <td className="px-3">
                <div className="font-weight-accent">{group.name}</div>
                <div className="fs-12 text-gray-500">{group.description}</div>
              </td>
            </tr>
          ))
          : (
            <tr>
              <td className="px-3">
                <p>{translator.get('messages.no-results-found')}</p>
              </td>
            </tr>
          )}
      </DashboxBody>
    </Dashbox>
  );
}

function ObservationGroupModal() {
  const translator = new Translator();
  const project = new Project();
  const authenticityToken = new Authenticity().token();
  const self = React.createRef();
  const formId = 'observation_group';
  const selectedObservationGroup = globalSelector('selectedObservationGroup');
  const updateSelectedObservationGroup = globalDispatch('selectedObservationGroup');
  const observationGroups = globalSelector('observationGroups');
  const updateObservationGroups = globalDispatch('observationGroups');
  const isUpdate = !!selectedObservationGroup?.code;
  const canEdit = project.userCan('manage_project');

  const closeModal = React.useCallback(() => {
    updateSelectedObservationGroup(null);
  });

  const submitObservationGroup = React.useCallback((e) => {
    e.preventDefault();

    loadingOverlay(self.current, 'show');

    const httpCall = isUpdate ? patchJSON : postJSON;
    const submissionUrl = selectedObservationGroup?.submissionUrl || project.observationGroupsPath;
    const form = parseForm(e.target);

    httpCall(
      submissionUrl,
      form,
      (data) => {
        loadingOverlay(self.current, 'hide');
        updateObservationGroups(updateByKey(observationGroups, data.object));
        closeModal();
      },
    );
  });

  const destroyObservationGroup = React.useCallback(() => {
    BootBox.confirm({
      size: 'small',
      message: translator.get('messages.are-you-sure'),
      callback: (success) => {
        if (!success) return;

        loadingOverlay(self.current, 'show');

        deleteJSON(
          selectedObservationGroup.submissionUrl,
          {},
          (data) => {
            loadingOverlay(self.current, 'hide');
            updateObservationGroups(removeByKey(observationGroups, data.object.key));
            closeModal();
          },
        );
      },
    });
  });

  return (
    <Modal centered show={!!selectedObservationGroup} onHide={closeModal}>
      <Modal.Header closeButton onHide={closeModal}>
        <Modal.Title>
          {isUpdate ? translator.get('general.update') : translator.get('general.add')}
          { ' ' }
          {translator.get('general.observation_group')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body ref={self} className="pt-2 px-3">
        <Form
          id={formId}
          onSubmit={submitObservationGroup}
          authenticityToken={authenticityToken}
          validate
          withBorders
        >
          <Col sm={12}>
            <Input
              id="name"
              formId={formId}
              label={translator.get('general.name')}
              defaultValue={selectedObservationGroup?.name}
              readOnly={!canEdit}
              placeholder="Department X"
              validation="required"
            />
          </Col>
          <Col sm={12}>
            <ListEditor
              id="observation_types"
              formId={formId}
              defaultValues={selectedObservationGroup?.observationTypes}
              label={translator.get('general.observation_types')}
            />
          </Col>
          <Col sm={12}>
            <ListEditor
              id="activity_types"
              formId={formId}
              defaultValues={selectedObservationGroup?.activityTypes}
              label={translator.get('general.activity_types')}
            />
          </Col>
          { canEdit && (
            <>
              <Spacer block />
              <Col sm={6}>
                {
                  isUpdate
                    ? (
                      <Button size="sm" variant="danger" onClick={destroyObservationGroup} withIcon block>
                        <Icon name="trash" />
                        { translator.get('general.delete').toUpperCase() }
                      </Button>
                    )
                    : (
                      <Button size="sm" variant="gray-200" onClick={closeModal} withIcon block>
                        <Icon name="times" />
                        { translator.get('general.cancel').toUpperCase() }
                      </Button>
                    )
                }
              </Col>
              <Col sm={6}>
                <Button size="sm" variant="primary" type="submit" withIcon block>
                  <Icon name="check" />
                  { translator.get('general.save').toUpperCase() }
                </Button>
              </Col>
            </>
          )}
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export function ObservationGroups(props) {
  const {
    observationGroups,
  } = props;

  return (
    <GlobalWrapper {...props}>
      <ObservationGroupInitialLoader observationGroups={observationGroups} />
      <ObservationGroupsTable />
      <ObservationGroupModal />
    </GlobalWrapper>
  );
}
