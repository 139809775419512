import * as React from 'react';
import { Col, Table } from 'react-bootstrap';
import { ReportPlotModalWrapper } from '../report_plot_base/ReportPlotModalWrapper.jsx';
import { HiddenInput, Input, Select } from '../../../duxfront/duxstrap/components/Form.jsx';
import { Translator } from '../../../global/translator';
import { AddPlotDataRowButton, ReportPlotDataForm } from '../report_plot_base/ReportPlotDataForm.jsx';
import {
  MODAL_TYPES, reportModalTypeSelector,
  reportPlotOptionsSelector,
  reportPlotSelectedKeySelector,
  reportPlotSelector,
} from '../report-utils';

export function ReportInteractivePlotDataForm() {
  const translator = new Translator();
  const plotOptions = reportPlotOptionsSelector();
  const reportPlotSelectedKey = reportPlotSelectedKeySelector();
  const plot = reportPlotSelector(reportPlotSelectedKey);
  const selectedModalType = reportModalTypeSelector();
  const show = plot && selectedModalType === MODAL_TYPES.INTERACTIVE_PLOT_DATA;
  const formId = 'interactive_plot_data';
  const title = `${translator.get('general.edit')} ${translator.get('general.datas')}`;
  const [plotDataDetails, setPlotDataDetails] = React.useState(null);
  const axisOptions = plot?.config?.axis?.map((axis) => (
    {
      name: axis.label,
      value: axis.id,
    }
  ));

  React.useEffect(() => {
    if (!plot) setPlotDataDetails(null);
  });

  return show && (
    <ReportPlotModalWrapper
      title={title}
      modalType={MODAL_TYPES.INTERACTIVE_PLOT_DATA}
    >
      { !plotDataDetails && (
        <Table size="sm" className="m-0 border-top" hover>
          <tbody>
            {plot.config.plots.map((details) => (
              <tr key={details.id} className="cursor-pointer" onClick={() => setPlotDataDetails(details)}>
                <td className="px-3">
                  <div className="font-weight-accent text-primary">{details.title}</div>
                </td>
              </tr>
            ))}
            <AddPlotDataRowButton plotKey={plot.key} setPlotDataDetails={setPlotDataDetails} />
          </tbody>
        </Table>
      )}
      { !!plotDataDetails && (
        <ReportPlotDataForm
          plotKey={plot.key}
          plotDataDetails={plotDataDetails}
          setPlotDataDetails={setPlotDataDetails}
          formId={formId}
        >
          <HiddenInput
            id="id"
            formId={formId}
            defaultValue={plotDataDetails?.id}
          />
          <Col sm={12}>
            <Input
              id="title"
              formId={formId}
              label={translator.get('general.title')}
              defaultValue={plotDataDetails?.title}
              placeholder="Daily Temperature (°C)"
              validation="required"
            />
          </Col>
          <Col xs={4}>
            <Select
              id="plot_type"
              formId={formId}
              label={translator.get('general.plot')}
              defaultValue={plotDataDetails?.type}
              options={plotOptions.plotTypeOptions}
            />
          </Col>
          <Col xs={4}>
            <Input
              id="color"
              formId={formId}
              label={translator.get('general.color')}
              defaultValue={plotDataDetails?.color}
              placeholder="#000000"
              validation="required"
              type="color"
            />
          </Col>
          <Col xs={4}>
            <Select
              id="axis"
              formId={formId}
              label={translator.get('general.axis')}
              defaultValue={plotDataDetails?.axis}
              options={axisOptions}
            />
          </Col>
        </ReportPlotDataForm>
      )}
    </ReportPlotModalWrapper>
  );
}
