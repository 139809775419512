import React from 'react';
import { Col, Modal } from 'react-bootstrap';
import { BootBox } from '../../../duxfront/duxstrap/vendor/bootbox';
import { Translator } from '../../../global/translator';
import { Authenticity } from '../../../global/authenticity';
import { Form, Input, Select } from '../../../duxfront/duxstrap/components/Form.jsx';
import { Spacer } from '../../../duxfront/duxstrap/components/Spacer.jsx';
import { Button } from '../../../duxfront/duxstrap/components/Button.jsx';
import { Icon } from '../../../duxfront/duxstrap/components/Icon.jsx';
import { loadingOverlay, parseForm, postJSON } from '../../../duxfront/plugins/dux-utils';
import { User } from '../../../global/user';

export function NewLocationModal({
  show,
  setShow,
  project,
  timezoneList,
  notificationLists,
  observationGroups,
}) {
  const translator = new Translator();
  const user = new User();
  const authenticityToken = new Authenticity().token();
  const self = React.createRef();
  const formId = 'new-location';
  const closeModal = React.useCallback(() => setShow(null));

  const submitProject = React.useCallback((e) => {
    e.preventDefault();
    loadingOverlay(self.current, 'show');

    postJSON(
      `/projects/${project.code}/locations`,
      { location: parseForm(e.target)[formId] },
      (data) => {
        loadingOverlay(self.current, 'hide');
        global.window.location.href = `/projects/${project.code}/locations/${data.object.code}`;
      },
      (data) => {
        loadingOverlay(self.current, 'hide');
        BootBox.alert(`Error: ${data.responseJSON.msg || 'An unknown error occurred'}`);
      },
    );
  });

  return (
    <Modal centered show={show} onHide={closeModal}>
      <Modal.Header closeButton onHide={closeModal}>
        <Modal.Title>
          {translator.get('titles.new-location')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body ref={self} className="pt-2 px-3">
        <Form
          id={formId}
          onSubmit={submitProject}
          authenticityToken={authenticityToken}
          validate
          withBorders
        >
          <Col sm={12}>
            <Input
              id="name"
              formId={formId}
              label={translator.get('general.title')}
              placeholder="Chicago Warehouse Shipping 74978"
              validation="required"
            />
          </Col>

          <Col sm={12} md={4}>
            <Select
              formId={formId}
              id="timezone"
              label={translator.get('general.timezone')}
              defaultValue={user.timezone}
              options={timezoneList}
            />
          </Col>

          <Col sm={12} md={4}>
            <Input
              id="latitude"
              formId={formId}
              label={translator.get('general.latitude')}
              placeholder="0.00"
              validation="required"
            />
          </Col>

          <Col sm={12} md={4}>
            <Input
              id="longitude"
              formId={formId}
              label={translator.get('general.longitude')}
              placeholder="0.00"
              validation="required"
            />
          </Col>

          <Col sm={12} md={6}>
            <Select
              id="notification_list_ids"
              formId={formId}
              label={translator.get('general.notification_lists')}
              multiple
              options={notificationLists}
            />
          </Col>

          <Col sm={12} md={6}>
            <Select
              id="observation_group_ids"
              formId={formId}
              label={translator.get('general.observation_groups')}
              multiple
              options={observationGroups}
            />
          </Col>

          <Spacer block />

          <Col sm={6}>
            <Button size="sm" variant="gray-200" onClick={closeModal} withIcon block>
              <Icon name="times" />
              { translator.get('general.cancel').toUpperCase() }
            </Button>
          </Col>
          <Col sm={6}>
            <Button size="sm" variant="primary" type="submit" withIcon block>
              <Icon name="check" />
              { translator.get('general.add').toUpperCase() }
            </Button>
          </Col>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
