import React from 'react';
import { Col, Modal } from 'react-bootstrap';
import { BootBox } from '../../../duxfront/duxstrap/vendor/bootbox';
import { Translator } from '../../../global/translator';
import { Authenticity } from '../../../global/authenticity';
import { Form, Input } from '../../../duxfront/duxstrap/components/Form.jsx';
import { Spacer } from '../../../duxfront/duxstrap/components/Spacer.jsx';
import { Button } from '../../../duxfront/duxstrap/components/Button.jsx';
import { Icon } from '../../../duxfront/duxstrap/components/Icon.jsx';
import { loadingOverlay, parseForm, postJSON } from '../../../duxfront/plugins/dux-utils';

export function NewProjectModal({
  show, setShow,
}) {
  const translator = new Translator();
  const authenticityToken = new Authenticity().token();
  const self = React.createRef();
  const formId = 'project';
  const closeModal = React.useCallback(() => setShow(null));

  const submitProject = React.useCallback((e) => {
    e.preventDefault();
    loadingOverlay(self.current, 'show');

    postJSON(
      '/projects',
      parseForm(e.target),
      (data) => {
        loadingOverlay(self.current, 'hide');
        global.window.location.href = data.object.viewPath;
      },
      (data) => {
        loadingOverlay(self.current, 'hide');
        BootBox.alert(`Error: ${data.responseJSON.msg || 'An unknown error occurred'}`);
      },
    );
  });

  return (
    <Modal centered show={show} onHide={closeModal}>
      <Modal.Header closeButton onHide={closeModal}>
        <Modal.Title>
          {translator.get('titles.new-project')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body ref={self} className="pt-2 px-3">
        <Form
          id={formId}
          onSubmit={submitProject}
          authenticityToken={authenticityToken}
          validate
          withBorders
        >
          <Col sm={12}>
            <Input
              id="title"
              formId={formId}
              label={translator.get('general.title')}
              placeholder="Johannesburg Plant 2738"
              validation="required"
            />
          </Col>
          <Spacer block />
          <Col sm={6}>
            <Button size="sm" variant="gray-200" onClick={closeModal} withIcon block>
              <Icon name="times" />
              { translator.get('general.cancel').toUpperCase() }
            </Button>
          </Col>
          <Col sm={6}>
            <Button size="sm" variant="primary" type="submit" withIcon block>
              <Icon name="check" />
              { translator.get('general.add').toUpperCase() }
            </Button>
          </Col>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
